import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "_components/core/Autocomplete_";
import DatePicker from "_components/core/DatePicker_";
import TimePicker from "_components/core/TimePicker_";
import GreenCheckbox from "_components/core/Checkbox_";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@mui/material/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { Controller } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

const useStyles = makeStyles((theme) => ({
  textfield: { backgroundColor: theme.palette.WhitePrg.main },
  autocomplete: {
    height: 8,
  },
  textarea: {
    width: "100%",
    border: `solid 1px ${theme.palette.LightGrayPrg.main} !important`,
    borderRadius: "4px",
    backgroundColor: theme.palette.WhitePrg.main,
  },
}));

// This is a dynamic component to render a form input based on type
// Currently this is only implemented in the mobile claim form

export const FormField = ({ field, register, control, errors }) => {
  const classes = useStyles();
  switch (field.type) {
    case "text":
      return (
        <Grid
          key={field.id}
          item
          xs={field.size === "auto" ? true : 12}
          md={field.size === "auto" ? true : field.md ? field.md : 3}
        >
          <TextField
            className={classes.textfield}
            name={field.name}
            label={field.label}
            InputLabelProps={{ shrink: true }}
            size="small"
            fullWidth
            variant="outlined"
            required={field.required}
            disabled={field.disabled}
            inputRef={register({ required: field.required ? "Required" : false })}
            error={errors[field.id]}
            helperText={errors[field.id]?.message}
            inputProps={{ maxLength: field.maxLength }}
            InputProps={{ readOnly: field.readOnly ?? false, "aria-readonly": field.readOnly ?? false }}
          />
        </Grid>
      );
    case "textarea":
      return (
        <Grid
          key={field.id}
          item
          xs={field.size === "auto" ? true : 12}
          md={field.size === "auto" ? true : field.md ? field.md : 3}
        >
          <TextField
            className={classes.textfield}
            name={field.name}
            label={field.label}
            InputLabelProps={{ shrink: true }}
            size="small"
            fullWidth
            variant="outlined"
            required={field.required}
            disabled={field.disabled}
            inputRef={register({ required: field.required ? "Required" : false })}
            error={errors[field.id]}
            helperText={errors[field.id]?.message}
            multiline
            minRows="3"
          />
        </Grid>
      );
    case "autocomplete":
      return (
        <Grid
          key={field.id}
          item
          xs={field.size === "auto" ? true : 12}
          md={field.size === "auto" ? true : field.md ? field.md : 3}
        >
          <Autocomplete
            options={field.options ? field.options : []}
            optionSchema={field.optionSchema}
            className={classes.autocomplete}
            control={control}
            name={field.name}
            label={field.label}
            required={field.required}
            disabled={field.disabled}
            error={errors[field.id]}
            helperText={errors[field.id]?.message}
            filterOptions={field?.filterOptions}
          />
        </Grid>
      );
    case "date":
      return (
        <Grid
          key={field.id}
          item
          xs={field.size === "auto" ? true : 12}
          md={field.size === "auto" ? true : field.md ? field.md : 3}
        >
          <DatePicker
            name={field.name}
            label={field.label}
            control={control}
            required={field.required}
            disabled={field.disabled}
            error={errors[field.id]}
            helperText={errors[field.id]?.message}
            maxDate={field.maxDate}
          />
        </Grid>
      );
    case "time":
      return (
        <Grid
          key={field.id}
          item
          xs={field.size === "auto" ? true : 12}
          md={field.size === "auto" ? true : field.md ? field.md : 3}
        >
          <TimePicker
            name={field.name}
            label={field.label}
            control={control}
            required={field.required}
            disabled={field.disabled}
            error={errors[field.id]}
            helperText={errors[field.id]?.message}
            maxTime={field.maxTime}
            customValidationError={field.customValidationError}
          />
        </Grid>
      );
    case "checkbox":
      return (
        <Grid
          key={field.id}
          item
          xs={field.size === "auto" ? true : 12}
          md={field.size === "auto" ? true : field.md ? field.md : 3}
        >
          <FormControlLabel
            control={
              <Controller
                name={field.name}
                control={control}
                defaultValue=""
                render={({ ref, ...props }) => {
                  return (
                    <GreenCheckbox
                      {...props}
                      checked={props.value === 1 || props.value === true}
                      onChange={(e) => props.onChange(e.target.checked)}
                      disabled={field.disabled}
                      inputRef={ref}
                    />
                  );
                }}
              />
            }
            label={field.label}
          />
        </Grid>
      );
    case "radio":
      return (
        <Grid key={field.id} item xs={12} md={field.size === "auto" ? true : field.md ? field.md : 3}>
          <FormControl component="fieldset">
            <FormLabel
              component="legend"
              style={{
                fontSize: "12px",
                lineHeight: 0,
                marginLeft: "12.5px",
              }}
            >
              {field.label}
            </FormLabel>

            <Controller
              render={({ onChange, value, name, ref }) => (
                <RadioGroup
                  aria-label={field.label}
                  style={{ display: "inline-block" }}
                  ref={ref}
                  name={name}
                  required={field.required}
                  disabled={field.disabled}
                  value={value}
                >
                  {field.options ? (
                    field.options.map((option) => (
                      <FormControlLabel
                        value={option.value}
                        control={<Radio required={field.required} />}
                        onChange={() => onChange(option.value)}
                        label={option.label}
                        labelPlacement="start"
                        disabled={field.disabled}
                      />
                    ))
                  ) : (
                    <>
                      <FormControlLabel
                        value={true}
                        control={<Radio required={field.required} />}
                        onChange={() => onChange(true)}
                        label="Yes"
                        labelPlacement="start"
                        disabled={field.disabled}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio required={field.required} />}
                        onChange={() => onChange(false)}
                        label="No"
                        labelPlacement="start"
                        disabled={field.disabled}
                      />
                    </>
                  )}
                </RadioGroup>
              )}
              name={field.name}
              required={field.required}
              disabled={field.disabled}
              defaultValue={""}
              control={control}
            />
          </FormControl>
        </Grid>
      );
    case "label":
      return (
        <Grid key={field.id} item xs={field.size === "auto" ? true : 12} md={field.md ? field.md : 12}>
          <Typography variant="subtitle2" component="div" gutterBottom>
            {field.label}
          </Typography>
        </Grid>
      );
    case "spacer":
      return <Grid key={uuidv4()} item container xs={field.size}></Grid>;
    case "divider":
      return (
        <Grid key={uuidv4()} item xs={field.size === "auto" ? true : 12}>
          <Divider variant="middle" />
        </Grid>
      );
    default:
      return null;
  }
};
