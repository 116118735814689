import { REHYDRATE } from "redux-persist/lib/constants";
import { produce } from "immer";
import { normalize } from "normalizr";
import { powerGasClaimConstants } from "_constants";
import { powerGasClaimSchema } from "_schema";
let { allClaimsSchema, myClaimsSchema } = powerGasClaimSchema;

let initState = {
  all: {},
  my: {},
  myTeams: {},
  individual: {
    loading: false,
  },
  create: {},
  attachments: {},
  history: {},
  schema: {
    entityOptions: [],
    rdaOptions: {},
    filterOptions: [],
    filterSets: [],
    attachmentImageCategories: [],
    attachmentVideoCategories: [],
    attachmentDocumentCategories: {}, // {[parentType]: data}
  },
  damagingParties: {
    items: [],
    loading: false,
    error: null,
  },
  jobCosting: {
    invoice: {}, // [claimId]: {}
  },
  docs: {
    invoice: {},
    claimLetter: {},
    intentToBill: {},
    secondNotice: {},
    legalFinalNotice: {},
    finalNotice: {},
    releaseLetter: {},
    paymentReceipt: {}, // { [claimId-ledgerId]: data }
    balanceOwed: {},
    narrativeCreator: {},
    promissoryNote: {}, // {[claimId]: data}
    schemaClaimLetterReasons: [],
    schemaClaimLetterContacts: [],
    schemaClaimLetterLastSentence: {},
  },
};

export function claim(state = { ...initState }, action) {
  const { payload, type } = action;

  switch (type) {
    case powerGasClaimConstants.GET_ALL_CLAIMS_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        const normalizedData = normalize(payload, allClaimsSchema);
        draft["all"] = normalizedData;
        draft["all"]["columns"] = payload.columnFilters;
      });
    case powerGasClaimConstants.GET_MY_CLAIMS_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        const normalizedData = normalize(payload, myClaimsSchema);
        draft["my"] = normalizedData;
        draft["all"]["columns"] = payload.columnFilters;
      });
    case powerGasClaimConstants.GET_MY_TEAMS_CLAIMS_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        const normalizedData = normalize(payload, myClaimsSchema);
        draft["myTeams"] = normalizedData;
        draft["all"]["columns"] = payload.columnFilters;
      });
    case powerGasClaimConstants.GET_CLAIM_BY_ID_POWER_GAS_LOADING_TRUE:
      return {
        ...state,
        individual: {
          loading: true,
        },
      };
    case powerGasClaimConstants.GET_CLAIM_BY_ID_POWER_GAS_SUCCESS:
      // Note
      // we want to only store one claim at a time, since claims are 200kb each.
      // that can get expensive rather quickly -> regarding storage
      let { claimId, claim } = payload;
      return {
        ...state,
        individual: {
          [claimId]: { ...claim },
          loading: false,
        },
      };
    case powerGasClaimConstants.GET_DAMAGING_PARTIES_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        draft.damagingParties.loading = false;
        // Make sure items is an array, even if the response is empty
        draft.damagingParties.items = Array.isArray(action.payload) ? action.payload : [];
      });

    case powerGasClaimConstants.ADD_DAMAGING_PARTY_POWER_GAS_REQUEST:
      return produce(state, (draft) => {
        draft.damagingParties.loading = true;
        draft.damagingParties.error = null;
      });

    case powerGasClaimConstants.ADD_DAMAGING_PARTY_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        draft.damagingParties.loading = false;

        if (action.payload && typeof action.payload === "object") {
          const newItem = {
            nameID: action.payload.nameID,
            damagingName: action.payload.damagingName,
          };

          // Only add if we have valid values
          if (newItem.nameID !== undefined && newItem.damagingName) {
            // Check if the item already exists
            const exists = draft.damagingParties.items.some((item) => item.nameID === newItem.nameID);

            // Add only if it doesn't exist
            if (!exists) {
              draft.damagingParties.items.push(newItem);

              // Sort the array by damagingName if possible
              if (draft.damagingParties.items.length > 1) {
                draft.damagingParties.items.sort((a, b) => {
                  if (!a.damagingName || !b.damagingName) return 0;
                  return a.damagingName.localeCompare(b.damagingName);
                });
              }
            }
          }
        }
      });

    case powerGasClaimConstants.ADD_DAMAGING_PARTY_POWER_GAS_FAILURE:
      return produce(state, (draft) => {
        draft.damagingParties.loading = false;
        draft.damagingParties.error = action.payload;
      });
    case powerGasClaimConstants.SEARCH_DAMAGING_PARTIES_POWER_GAS_REQUEST:
      return produce(state, (draft) => {
        // Initialize damagingParties if needed
        if (!draft.damagingParties) {
          draft.damagingParties = {};
        }

        draft.damagingParties.searchLoading = true;
        draft.damagingParties.searchError = null;
      });

    case powerGasClaimConstants.SEARCH_DAMAGING_PARTIES_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        // Initialize damagingParties if it doesn't exist
        if (!draft.damagingParties) {
          draft.damagingParties = {};
        }
        draft.damagingParties.searchResults = action.payload;
        draft.damagingParties.searchLoading = false;
        draft.damagingParties.searchError = null;
      });

    case powerGasClaimConstants.SEARCH_DAMAGING_PARTIES_POWER_GAS_FAILURE:
      return produce(state, (draft) => {
        // Initialize damagingParties if it doesn't exist
        if (!draft.damagingParties) {
          draft.damagingParties = {};
        }
        draft.damagingParties.searchLoading = false;
        draft.damagingParties.searchError = action.payload;
      });

    case powerGasClaimConstants.CLEAR_DAMAGING_PARTIES_SEARCH_RESULTS:
      return produce(state, (draft) => {
        // Initialize damagingParties if it doesn't exist
        if (!draft.damagingParties) {
          draft.damagingParties = {};
        }
        draft.damagingParties.searchResults = [];
        draft.damagingParties.searchLoading = false;
        draft.damagingParties.searchError = null;
      });

    case powerGasClaimConstants.GET_SCHEMA_ENTITY_OPTIONS_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        let { options } = payload;
        draft["schema"]["entityOptions"] = [...options];
      });
    case powerGasClaimConstants.GET_SCHEMA_RDA_OPTIONS_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        let { options, entityId } = payload;
        draft["schema"]["rdaOptions"][entityId] = [...options];
      });
    case powerGasClaimConstants.GET_CREATE_CLAIM_FIELD_RULES_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        let { rules, entityId } = payload;
        draft["create"][entityId] = { ...rules };
      });
    case powerGasClaimConstants.GET_SCHEMA_FILTER_OPTIONS_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        draft["schema"]["filterOptions"] = [...payload];
      });
    case powerGasClaimConstants.GET_FILTER_SETS_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        draft["schema"]["filterSets"] = [...payload];
      });
    case powerGasClaimConstants.GET_CLAIM_ATTACHMENTS_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        let { claimId, files } = payload;
        draft["attachments"][claimId] = [...files];
      });

    case powerGasClaimConstants.GET_CLAIM_HISTORY_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        let { data, claimId } = payload;
        draft["history"][claimId] = [...data];
      });
    case powerGasClaimConstants.GET_JOB_COSTING_INVOICE_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        let { data, claimId } = payload;
        // only store one at a time
        draft["jobCosting"]["invoice"] = { [claimId]: { ...data } };
      });
    case powerGasClaimConstants.GET_CLAIM_INVOICE_DOCUMENT_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        let { data, claimId } = payload;
        draft["docs"]["invoice"] = {
          [claimId]: data,
        };
      });
    case powerGasClaimConstants.GET_CLAIM_DOCUMENT_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        let { data, claimId, letterType } = payload;

        draft["docs"]["claimLetter"] = {
          [`${claimId}-${letterType}`]: data,
        };
      });
    case powerGasClaimConstants.GET_CLAIM_DOCUMENT_SCHEMA_REASON_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        let { data } = payload;
        draft["docs"]["schemaClaimLetterReasons"] = data;
      });
    case powerGasClaimConstants.GET_CLAIM_DOCUMENT_SCHEMA_CONTACTS_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        let { data } = payload;
        draft["docs"]["schemaClaimLetterContacts"] = [...data];
      });
    case powerGasClaimConstants.GET_CLAIM_DOCUMENT_SCHEMA_LAST_SENTENCE_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        let { data, entityId } = payload;

        draft["docs"]["schemaClaimLetterLastSentence"] = {
          [entityId]: data,
        };
      });
    case powerGasClaimConstants.GET_CLAIM_ATTACHMENT_SCHEMA_IMAGE_CATEGORY_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        draft["schema"]["attachmentImageCategories"] = payload;
      });
    case powerGasClaimConstants.GET_CLAIM_ATTACHMENT_SCHEMA_VIDEO_CATEGORY_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        draft["schema"]["attachmentVideoCategories"] = payload;
      });
    case powerGasClaimConstants.GET_CLAIM_ATTACHMENT_SCHEMA_DOCUMENT_CATEGORY_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        let { data, parentType } = payload;

        draft["schema"]["attachmentDocumentCategories"] = {
          [parentType]: data,
        };
      });
    case powerGasClaimConstants.GET_CLAIM_NARRATIVE_CREATOR_DOCUMENT_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        let { data, claimId } = payload;

        draft["docs"]["narrativeCreator"] = { [claimId]: data };
      });
    case powerGasClaimConstants.GET_CLAIM_INTENT_TO_BILL_DOCUMENT_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        let { data, claimId } = payload;

        draft["docs"]["intentToBill"] = { [claimId]: data };
      });
    case powerGasClaimConstants.GET_CLAIM_PROMISSORY_DOCUMENT_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        let { data, claimId } = payload;

        draft["docs"]["promissoryNote"] = { [claimId]: data };
      });
    case powerGasClaimConstants.GET_CLAIM_LEGAL_FINAL_NOTICE_DOCUMENT_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        let { data, claimId } = payload;
        draft["docs"]["legalFinalNotice"] = { [claimId]: data };
      });
    case powerGasClaimConstants.GET_CLAIM_FINAL_NOTICE_DOCUMENT_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        let { data, claimId } = payload;

        draft["docs"]["finalNotice"] = { [claimId]: data };
      });
    case powerGasClaimConstants.GET_CLAIM_RELEASE_LETTER_DOCUMENT_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        let { data, claimId } = payload;

        draft["docs"]["releaseLetter"] = { [claimId]: data };
      });
    case powerGasClaimConstants.GET_CLAIM_SECOND_NOTICE_DOCUMENT_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        let { data, claimId } = payload;

        draft["docs"]["secondNotice"] = { [claimId]: data };
      });
    case powerGasClaimConstants.GET_CLAIM_PAYMENT_RECEIPT_DOCUMENT_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        let { data, claimId, ledgerId } = payload;

        draft["docs"]["paymentReceipt"] = { [`${claimId}-${ledgerId}`]: data };
      });
    case powerGasClaimConstants.GET_CLAIM_BALANCE_OWED_DOCUMENT_POWER_GAS_SUCCESS:
      return produce(state, (draft) => {
        let { data, claimId } = payload;

        draft["docs"]["balanceOwed"] = { [claimId]: data };
      });
    case REHYDRATE: {
      if (payload && payload.powerGas && payload.powerGas.claim) {
        return { ...payload.powerGas.claim };
      } else {
        return { ...initState };
      }
    }

    case powerGasClaimConstants.POWER_GAS_CLAIM_CLEAR:
      return {
        ...initState,
      };
    default:
      return state;
  }
}
