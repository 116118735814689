import { powerGasClaimConstants } from "_constants";

const getAll = (
  page,
  rowsPerPage,
  filterModal,
  search,
  searchField,
  searchFields,
  isDispatchAdmin,
  allClaimsCount,
  restrictedStatusIds
) => {
  return {
    type: powerGasClaimConstants.GET_ALL_CLAIMS_POWER_GAS_REQUEST,
    page,
    rowsPerPage,
    filterModal,
    search,
    searchField,
    searchFields,
    isDispatchAdmin,
    allClaimsCount,
    restrictedStatusIds,
  };
};

const getMy = (page, rowsPerPage, filterModal, search, searchField, searchFields, userId) => {
  return {
    type: powerGasClaimConstants.GET_MY_CLAIMS_POWER_GAS_REQUEST,
    page,
    rowsPerPage,
    filterModal,
    search,
    searchField,
    searchFields,
    userId,
  };
};

const getMyTeams = (
  page,
  rowsPerPage,
  filterModal,
  search,
  searchField,
  searchFields,
  isDispatchAdmin,
  allClaimsCount,
  userLogginIn
) => {
  return {
    type: powerGasClaimConstants.GET_MY_TEAMS_CLAIMS_POWER_GAS_REQUEST,
    page,
    rowsPerPage,
    filterModal,
    search,
    searchField,
    searchFields,
    isDispatchAdmin,
    allClaimsCount,
    userLogginIn,
  };
};

const getById = (claimId, restrictedStatusIds) => {
  return {
    type: powerGasClaimConstants.GET_CLAIM_BY_ID_POWER_GAS_REQUEST,
    claimId,
    restrictedStatusIds,
  };
};

const update = (claimId, payload, restrictedStatusIds) => {
  return {
    type: powerGasClaimConstants.UPDATE_POWER_GAS_CLAIM_REQUEST,
    claimId,
    payload,
    restrictedStatusIds,
  };
};

const deleteClaim = (
  claimId,
  currentQuery,
  page,
  rowsPerPage,
  filterModal,
  search,
  isDispatchAdmin,
  allClaimsCount,
  restrictedStatusIds,
  trackChanges,
  data,
  callback,
  searchField,
  searchFields
) => {
  return {
    type: powerGasClaimConstants.DELETE_POWER_GAS_CLAIM_REQUEST,
    claimId,
    currentQuery,
    page,
    rowsPerPage,
    filterModal,
    search,
    isDispatchAdmin,
    allClaimsCount,
    restrictedStatusIds,
    trackChanges,
    data,
    callback,
    searchField,
    searchFields,
  };
};

const getDamagingParties = () => {
  return {
    type: powerGasClaimConstants.GET_DAMAGING_PARTIES_POWER_GAS_REQUEST,
  };
};

const addDamagingParty = (damagingName, data) => {
  return {
    type: powerGasClaimConstants.ADD_DAMAGING_PARTY_POWER_GAS_REQUEST,
    damagingName,
    data,
  };
};

export const searchDamagingParties = (searchTerm) => {
  console.log("Action creator called with search term:", searchTerm);

  return {
    type: powerGasClaimConstants.SEARCH_DAMAGING_PARTIES_POWER_GAS_REQUEST,
    searchTerm,
  };
};

export const clearDamagingPartiesSearchResults = () => {
  return {
    type: powerGasClaimConstants.CLEAR_DAMAGING_PARTIES_SEARCH_RESULTS,
  };
};

const getSchemaEntityOptions = () => {
  return {
    type: powerGasClaimConstants.GET_SCHEMA_ENTITY_OPTIONS_POWER_GAS_REQUEST,
  };
};

const getCreateClaimFieldRules = (entityId) => {
  return {
    type: powerGasClaimConstants.GET_CREATE_CLAIM_FIELD_RULES_POWER_GAS_REQUEST,
    entityId,
  };
};

const getSchemaRdaOptions = (entityId) => {
  return {
    type: powerGasClaimConstants.GET_SCHEMA_RDA_OPTIONS_POWER_GAS_REQUEST,
    entityId,
  };
};

const getSchemaFilterConditions = () => {
  return {
    type: powerGasClaimConstants.GET_SCHEMA_FILTER_OPTIONS_POWER_GAS_REQUEST,
  };
};

const getFilterSets = () => {
  return {
    type: powerGasClaimConstants.GET_FILTER_SETS_POWER_GAS_REQUEST,
  };
};

const createFilterSet = (payload) => {
  return {
    type: powerGasClaimConstants.CREATE_FILTER_SET_POWER_GAS_REQUEST,
    payload,
  };
};

const updateFilterSet = (id, payload) => {
  return {
    type: powerGasClaimConstants.UPDATE_FILTER_SET_POWER_GAS_REQUEST,
    id,
    payload,
  };
};

const removeFilterSet = (id) => {
  return {
    type: powerGasClaimConstants.REMOVE_FILTER_SET_POWER_GAS_REQUEST,
    id,
  };
};

const duplicateFilterSet = (name, id) => {
  return {
    type: powerGasClaimConstants.DUPLICATE_FILTER_SET_POWER_GAS_REQUEST,
    name,
    id,
  };
};

const uploadFile = (files, fieldValues, claimId) => {
  return {
    type: powerGasClaimConstants.UPLOAD_CLAIM_ATTACHMENT_POWER_GAS_REQUEST,
    payload: { ...fieldValues, files },
    claimId,
  };
};

const handleSaveRotation = (file, GCSPath, claimId, fileID, fk_categoryID) => {
  return {
    type: powerGasClaimConstants.SAVE_ROTATION_POWER_GAS_REQUEST,
    payload: { file, GCSPath, claimId, fileID, fk_categoryID },
  };
};

const getFiles = (claimId) => {
  return {
    type: powerGasClaimConstants.GET_CLAIM_ATTACHMENTS_POWER_GAS_REQUEST,
    claimId,
  };
};

const sortOrder = (payload, claimId) => {
  return {
    type: powerGasClaimConstants.SORT_ATTACHMENTS_POWER_GAS_CLAIM_REQUEST,
    payload,
    claimId,
  };
};

const updateCategory = (payload, claimId) => {
  return {
    type: powerGasClaimConstants.UPDATE_CATEGORY_ATTACHMENTS_POWER_GAS_CLAIM_REQUEST,
    payload,
    claimId,
  };
};

const getSignedUrl = (path, expire) => {
  return {
    type: powerGasClaimConstants.GET_SIGNED_ATTACHMENT_LINK_POWER_GAS_REQUEST,
    path,
    expire,
  };
};

const postPayment = (claimId, data) => {
  return {
    type: powerGasClaimConstants.POST_PAYMENT_POWER_GAS_REQUEST,
    payload: { ...data },
    claimId,
  };
};

const getClaimHistory = (claimId) => {
  return {
    type: powerGasClaimConstants.GET_CLAIM_HISTORY_POWER_GAS_REQUEST,
    claimId,
  };
};

const getClaimJobCostingInvoice = (claimId) => {
  return {
    type: powerGasClaimConstants.GET_JOB_COSTING_INVOICE_POWER_GAS_REQUEST,
    claimId,
  };
};

const claimInvoiceTimeStamp = (claimId) => {
  return {
    type: powerGasClaimConstants.CLAIM_INVOICE_TIMESTAMP_POWER_GAS_REQUEST,
    claimId,
  };
};

const claimPostMaterialItem = (payload, claimId, invoiceId) => {
  return {
    type: powerGasClaimConstants.POST_CLAIM_MATERIAL_ITEM_POWER_GAS_REQUEST,
    payload,
    claimId,
    invoiceId,
  };
};

const claimPostInHouseLabor = (payload, claimId, invoiceId) => {
  return {
    type: powerGasClaimConstants.POST_CLAIM_IN_HOUSE_LABOR_ITEM_POWER_GAS_REQUEST,
    payload,
    claimId,
    invoiceId,
  };
};

const claimPostVehicleItem = (payload, claimId, invoiceId) => {
  return {
    type: powerGasClaimConstants.POST_CLAIM_VEHICLE_ITEM_POWER_GAS_REQUEST,
    payload,
    claimId,
    invoiceId,
  };
};

const claimPostContractorItem = (payload, claimId, invoiceId) => {
  return {
    type: powerGasClaimConstants.POST_CLAIM_CONTRACTOR_ITEM_POWER_GAS_REQUEST,
    payload,
    claimId,
    invoiceId,
  };
};

const claimPostLossOfGasItem = (payload, claimId, invoiceId) => {
  return {
    type: powerGasClaimConstants.POST_CLAIM_LOSS_OF_GAS_ITEM_POWER_GAS_REQUEST,
    payload,
    claimId,
    invoiceId,
  };
};

const claimJobCostingDeleteMaterialItem = (invoiceMaterialItemIds, invoiceId, claimId) => {
  return {
    type: powerGasClaimConstants.DELETE_CLAIM_JOB_COSTING_MATERIAL_ITEM_POWER_GAS_REQUEST,
    invoiceMaterialItemIds,
    invoiceId,
    claimId,
  };
};

const claimJobCostingDeleteInHouseLaborItem = (invoiceLaborItemIds, invoiceId, claimId) => {
  return {
    type: powerGasClaimConstants.DELETE_CLAIM_JOB_COSTING_IN_HOUSE_LABOR_ITEM_POWER_GAS_REQUEST,
    invoiceLaborItemIds,
    invoiceId,
    claimId,
  };
};

const claimJobCostingDeleteVehicleItem = (invoiceVehicleItemIds, invoiceId, claimId) => {
  return {
    type: powerGasClaimConstants.DELETE_CLAIM_JOB_COSTING_VEHICLE_ITEM_POWER_GAS_REQUEST,
    invoiceVehicleItemIds,
    invoiceId,
    claimId,
  };
};

const claimJobCostingDeleteContractorItem = (invoiceContractorItemIds, invoiceId, claimId) => {
  return {
    type: powerGasClaimConstants.DELETE_CLAIM_JOB_COSTING_CONTRACTOR_ITEM_POWER_GAS_REQUEST,
    invoiceContractorItemIds,
    invoiceId,
    claimId,
  };
};

const claimJobCostingDeleteLossOfGasItem = (invoiceLossOfGasItemIds, invoiceId, claimId) => {
  return {
    type: powerGasClaimConstants.DELETE_CLAIM_JOB_COSTING_LOSS_OF_GAS_ITEM_POWER_GAS_REQUEST,
    invoiceLossOfGasItemIds,
    invoiceId,
    claimId,
  };
};

const claimUploadJobCosting = (file, name, claimId, userId, username) => {
  return {
    type: powerGasClaimConstants.UPLOAD_CLAIM_JOB_COSTING_SHEET_POWER_GAS_REQUEST,
    file,
    name,
    claimId,
    userId,
    username,
  };
};

const claimUploadDocument = (file, name, claimId, userId, username, parent, category, fileType) => {
  return {
    type: powerGasClaimConstants.UPLOAD_CLAIM_DOCUMENT_POWER_GAS_REQUEST,
    file,
    name,
    claimId,
    userId,
    username,
    parent,
    category,
    fileType,
  };
};

const getClaimInvoiceDocument = (claimId) => {
  return {
    type: powerGasClaimConstants.GET_CLAIM_INVOICE_DOCUMENT_POWER_GAS_REQUEST,
    claimId,
  };
};

const getClaimDocument = (claimId, letterType, contacts, reason) => {
  return {
    type: powerGasClaimConstants.GET_CLAIM_DOCUMENT_POWER_GAS_REQUEST,
    claimId,
    letterType,
    contacts,
    reason,
  };
};

const getClaimDocumentSchemaReason = () => {
  return {
    type: powerGasClaimConstants.GET_CLAIM_DOCUMENT_SCHEMA_REASON_POWER_GAS_REQUEST,
  };
};

const addClaimDocumentSchemaReason = (LetterReason) => {
  return {
    type: powerGasClaimConstants.ADD_CLAIM_DOCUMENT_SCHEMA_REASON_POWER_GAS_REQUEST,
    LetterReason,
  };
};

const editClaimDocumentSchemaReason = (LRID, LetterReason) => {
  return {
    type: powerGasClaimConstants.EDIT_CLAIM_DOCUMENT_SCHEMA_REASON_POWER_GAS_REQUEST,
    LRID,
    LetterReason,
  };
};

const removeClaimDocumentSchemaReason = (LRID) => {
  return {
    type: powerGasClaimConstants.REMOVE_CLAIM_DOCUMENT_SCHEMA_REASON_POWER_GAS_REQUEST,
    LRID,
  };
};

const getClaimDocumentSchemaContacts = () => {
  return {
    type: powerGasClaimConstants.GET_CLAIM_DOCUMENT_SCHEMA_CONTACTS_POWER_GAS_REQUEST,
  };
};

const getClaimDocumentSchemaLastSentence = (entityId) => {
  return {
    type: powerGasClaimConstants.GET_CLAIM_DOCUMENT_SCHEMA_LAST_SENTENCE_POWER_GAS_REQUEST,
    entityId,
  };
};

const getAttachmentImageCategoriesSchema = () => {
  return {
    type: powerGasClaimConstants.GET_CLAIM_ATTACHMENT_SCHEMA_IMAGE_CATEGORY_POWER_GAS_REQUEST,
  };
};

const getAttachmentVideoCategoriesSchema = () => {
  return {
    type: powerGasClaimConstants.GET_CLAIM_ATTACHMENT_SCHEMA_VIDEO_CATEGORY_POWER_GAS_REQUEST,
  };
};

const getAttachmentDocumentCategoriesSchema = (parentType) => {
  return {
    type: powerGasClaimConstants.GET_CLAIM_ATTACHMENT_SCHEMA_DOCUMENT_CATEGORY_POWER_GAS_REQUEST,
    parentType,
  };
};

const getClaimNarrativeCreatorDocument = (claimId) => {
  return {
    type: powerGasClaimConstants.GET_CLAIM_NARRATIVE_CREATOR_DOCUMENT_POWER_GAS_REQUEST,
    claimId,
  };
};

const getClaimIntentToBillDocument = (claimId) => {
  return {
    type: powerGasClaimConstants.GET_CLAIM_INTENT_TO_BILL_DOCUMENT_POWER_GAS_REQUEST,
    claimId,
  };
};

const getClaimPromissoryNoteDocument = (claimId) => {
  return {
    type: powerGasClaimConstants.GET_CLAIM_PROMISSORY_DOCUMENT_POWER_GAS_REQUEST,
    claimId,
  };
};
const getClaimReleaseLetterDocument = (claimId) => {
  return {
    type: powerGasClaimConstants.GET_CLAIM_RELEASE_LETTER_DOCUMENT_POWER_GAS_REQUEST,
    claimId,
  };
};

const getClaimSecondNoticeDocument = (claimId) => {
  return {
    type: powerGasClaimConstants.GET_CLAIM_SECOND_NOTICE_DOCUMENT_POWER_GAS_REQUEST,
    claimId,
  };
};

const getClaimFinalNoticeDocument = (claimId) => {
  return {
    type: powerGasClaimConstants.GET_CLAIM_FINAL_NOTICE_DOCUMENT_POWER_GAS_REQUEST,
    claimId,
  };
};

const getClaimLegalFinalNoticeDocument = (claimId) => {
  return {
    type: powerGasClaimConstants.GET_CLAIM_LEGAL_FINAL_NOTICE_DOCUMENT_POWER_GAS_REQUEST,
    claimId,
  };
};

const getClaimPaymentReceiptDocument = (claimId, ledgerId) => {
  return {
    type: powerGasClaimConstants.GET_CLAIM_PAYMENT_RECEIPT_DOCUMENT_POWER_GAS_REQUEST,
    claimId,
    ledgerId,
  };
};

const getClaimBalanceOwedDocument = (claimId) => {
  return {
    type: powerGasClaimConstants.GET_CLAIM_BALANCE_OWED_DOCUMENT_POWER_GAS_REQUEST,
    claimId,
  };
};
const deleteLedger = (ledgerId, claimId) => {
  return {
    type: powerGasClaimConstants.REMOVE_LEDGER_POWER_GAS_REQUEST,
    ledgerId,
    claimId,
  };
};
const updateLedger = (payload, watchId, cb = null) => {
  return {
    type: powerGasClaimConstants.UPDATE_LEDGER_POWER_GAS_REQUEST,
    payload,
    watchId,
    cb,
  };
};
const deleteStatusNote = (id, watchId) => {
  return {
    type: powerGasClaimConstants.REMOVE_STATUS_NOTE_POWER_GAS_REQUEST,
    id,
    watchId,
  };
};
const deleteActionNote = (actionId, claimId) => {
  return {
    type: powerGasClaimConstants.DELETE_ACTION_NOTE_POWER_GAS_REQUEST,
    actionId,
    claimId,
  };
};

const saveColumnFilter = (filters) => {
  return {
    type: powerGasClaimConstants.SAVE_POWER_GAS_COLUMN_FILTER_REQUEST,
    filters,
  };
};
export const powerGasClaimActions = {
  getAll,
  getMy,
  getMyTeams,
  getById,
  update,
  deleteClaim,
  getSchemaEntityOptions,
  getCreateClaimFieldRules,
  getSchemaRdaOptions,
  getSchemaFilterConditions,
  getFilterSets,
  createFilterSet,
  updateFilterSet,
  removeFilterSet,
  duplicateFilterSet,
  uploadFile,
  handleSaveRotation,
  getFiles,
  sortOrder,
  updateCategory,
  getSignedUrl,
  postPayment,
  getClaimHistory,
  getClaimJobCostingInvoice,
  claimInvoiceTimeStamp,
  claimPostMaterialItem,
  claimPostInHouseLabor,
  claimPostVehicleItem,
  claimPostContractorItem,
  claimPostLossOfGasItem,
  claimJobCostingDeleteMaterialItem,
  claimJobCostingDeleteInHouseLaborItem,
  claimJobCostingDeleteVehicleItem,
  claimJobCostingDeleteContractorItem,
  claimJobCostingDeleteLossOfGasItem,
  claimUploadJobCosting,
  getClaimInvoiceDocument,
  claimUploadDocument,
  getClaimDocument,
  getClaimDocumentSchemaReason,
  getClaimDocumentSchemaContacts,
  getClaimDocumentSchemaLastSentence,
  getAttachmentImageCategoriesSchema,
  getAttachmentVideoCategoriesSchema,
  getAttachmentDocumentCategoriesSchema,
  getClaimNarrativeCreatorDocument,
  getClaimIntentToBillDocument,
  getClaimPromissoryNoteDocument,
  getClaimLegalFinalNoticeDocument,
  getClaimFinalNoticeDocument,
  getClaimReleaseLetterDocument,
  getClaimSecondNoticeDocument,
  getClaimPaymentReceiptDocument,
  getClaimBalanceOwedDocument,
  deleteLedger,
  updateLedger,
  deleteStatusNote,
  deleteActionNote,
  addClaimDocumentSchemaReason,
  editClaimDocumentSchemaReason,
  removeClaimDocumentSchemaReason,
  saveColumnFilter,
  getDamagingParties,
  addDamagingParty,
  searchDamagingParties,
  clearDamagingPartiesSearchResults,
};
