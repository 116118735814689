export const powerGasClaimConstants = {
  GET_ALL_CLAIMS_POWER_GAS_REQUEST: "GET_ALL_CLAIMS_POWER_GAS_REQUEST",
  GET_ALL_CLAIMS_POWER_GAS_SUCCESS: "GET_ALL_CLAIMS_POWER_GAS_SUCCESS",
  GET_MY_CLAIMS_POWER_GAS_REQUEST: "GET_MY_CLAIMS_POWER_GAS_REQUEST",
  GET_MY_CLAIMS_POWER_GAS_SUCCESS: "GET_MY_CLAIMS_POWER_GAS_SUCCESS",
  GET_MY_TEAMS_CLAIMS_POWER_GAS_REQUEST: "GET_MY_TEAMS_CLAIMS_POWER_GAS_REQUEST",
  GET_MY_TEAMS_CLAIMS_POWER_GAS_SUCCESS: "GET_MY_TEAMS_CLAIMS_POWER_GAS_SUCCESS",
  GET_CLAIM_BY_ID_POWER_GAS_REQUEST: "GET_CLAIM_BY_ID_POWER_GAS_REQUEST",
  GET_CLAIM_BY_ID_POWER_GAS_SUCCESS: "GET_CLAIM_BY_ID_POWER_GAS_SUCCESS",
  UPDATE_POWER_GAS_CLAIM_REQUEST: "UPDATE_POWER_GAS_CLAIM_REQUEST",
  UPDATE_POWER_GAS_CLAIM_SUCCESS: "UPDATE_POWER_GAS_CLAIM_SUCCESS",
  DELETE_POWER_GAS_CLAIM_REQUEST: "DELETE_POWER_GAS_CLAIM_REQUEST",
  GET_CLAIM_BY_ID_POWER_GAS_LOADING_TRUE: "GET_CLAIM_BY_ID_POWER_GAS_LOADING_TRUE",
  GET_SCHEMA_ENTITY_OPTIONS_POWER_GAS_REQUEST: "GET_SCHEMA_ENTITY_OPTIONS_POWER_GAS_REQUEST",
  GET_SCHEMA_ENTITY_OPTIONS_POWER_GAS_SUCCESS: "GET_SCHEMA_ENTITY_OPTIONS_POWER_GAS_SUCCESS",
  GET_CREATE_CLAIM_FIELD_RULES_POWER_GAS_REQUEST: "GET_CREATE_CLAIM_FIELD_RULES_POWER_GAS_REQUEST",
  GET_CREATE_CLAIM_FIELD_RULES_POWER_GAS_SUCCESS: "GET_CREATE_CLAIM_FIELD_RULES_POWER_GAS_SUCCESS",
  GET_SCHEMA_RDA_OPTIONS_POWER_GAS_REQUEST: "GET_SCHEMA_RDA_OPTIONS_POWER_GAS_REQUEST",
  GET_SCHEMA_RDA_OPTIONS_POWER_GAS_SUCCESS: "GET_SCHEMA_RDA_OPTIONS_POWER_GAS_SUCCESS",
  GET_SCHEMA_FILTER_OPTIONS_POWER_GAS_SUCCESS: "GET_SCHEMA_FILTER_OPTIONS_POWER_GAS_SUCCESS",
  GET_SCHEMA_FILTER_OPTIONS_POWER_GAS_REQUEST: "GET_SCHEMA_FILTER_OPTIONS_POWER_GAS_REQUEST",
  GET_FILTER_SETS_POWER_GAS_REQUEST: "GET_FILTER_SETS_POWER_GAS_REQUEST",
  GET_FILTER_SETS_POWER_GAS_SUCCESS: "GET_FILTER_SETS_POWER_GAS_SUCCESS",
  CREATE_FILTER_SET_POWER_GAS_REQUEST: "CREATE_FILTER_SET_POWER_GAS_REQUEST",
  CREATE_FILTER_SET_POWER_GAS_SUCCESS: "CREATE_FILTER_SET_POWER_GAS_SUCCESS",
  UPDATE_FILTER_SET_POWER_GAS_REQUEST: "UPDATE_FILTER_SET_POWER_GAS_REQUEST",
  UPDATE_FILTER_SET_POWER_GAS_SUCCESS: "UPDATE_FILTER_SET_POWER_GAS_SUCCESS",
  REMOVE_FILTER_SET_POWER_GAS_REQUEST: "REMOVE_FILTER_SET_POWER_GAS_REQUEST",
  REMOVE_FILTER_SET_POWER_GAS_SUCCESS: "REMOVE_FILTER_SET_POWER_GAS_SUCCESS",
  DUPLICATE_FILTER_SET_POWER_GAS_REQUEST: "DUPLICATE_FILTER_SET_POWER_GAS_REQUEST",
  DUPLICATE_FILTER_SET_POWER_GAS_SUCCESS: "DUPLICATE_FILTER_SET_POWER_GAS_SUCCESS",
  UPLOAD_CLAIM_ATTACHMENT_POWER_GAS_REQUEST: "UPLOAD_CLAIM_ATTACHMENT_POWER_GAS_REQUEST",
  UPLOAD_CLAIM_ATTACHMENT_POWER_GAS_SUCCESS: "UPLOAD_CLAIM_ATTACHMENT_POWER_GAS_SUCCESS",
  SAVE_ROTATION_POWER_GAS_REQUEST: "SAVE_ROTATION_POWER_GAS_REQUEST",
  GET_CLAIM_ATTACHMENTS_POWER_GAS_REQUEST: "GET_CLAIM_ATTACHMENTS_POWER_GAS_REQUEST",
  GET_CLAIM_ATTACHMENTS_POWER_GAS_SUCCESS: "GET_CLAIM_ATTACHMENTS_POWER_GAS_SUCCESS",
  GET_SIGNED_ATTACHMENT_LINK_POWER_GAS_REQUEST: "GET_SIGNED_ATTACHMENT_LINK_POWER_GAS_REQUEST",
  GET_SIGNED_ATTACHMENT_LINK_POWER_GAS_SUCCESS: "GET_SIGNED_ATTACHMENT_LINK_POWER_GAS_SUCCESS",
  POST_PAYMENT_POWER_GAS_REQUEST: "POST_PAYMENT_POWER_GAS_REQUEST",
  POST_PAYMENT_POWER_GAS_SUCCESS: "POST_PAYMENT_POWER_GAS_SUCCESS",
  GET_CLAIM_HISTORY_POWER_GAS_REQUEST: "GET_CLAIM_HISTORY_POWER_GAS_REQUEST",
  GET_CLAIM_HISTORY_POWER_GAS_SUCCESS: "GET_CLAIM_HISTORY_POWER_GAS_SUCCESS",
  GET_JOB_COSTING_INVOICE_POWER_GAS_REQUEST: "GET_JOB_COSTING_INVOICE_POWER_GAS_REQUEST",
  GET_JOB_COSTING_INVOICE_POWER_GAS_SUCCESS: "GET_JOB_COSTING_INVOICE_POWER_GAS_SUCCESS",
  CLAIM_INVOICE_TIMESTAMP_POWER_GAS_REQUEST: "CLAIM_INVOICE_TIMESTAMP_POWER_GAS_REQUEST",
  CLAIM_INVOICE_TIMESTAMP_POWER_GAS_SUCCESS: "CLAIM_INVOICE_TIMESTAMP_POWER_GAS_SUCCESS",
  POST_CLAIM_MATERIAL_ITEM_POWER_GAS_REQUEST: "POST_CLAIM_MATERIAL_ITEM_POWER_GAS_REQUEST",
  POST_CLAIM_MATERIAL_ITEM_POWER_GAS_SUCCESS: "POST_CLAIM_MATERIAL_ITEM_POWER_GAS_SUCCESS",
  POST_CLAIM_IN_HOUSE_LABOR_ITEM_POWER_GAS_REQUEST: "POST_CLAIM_IN_HOUSE_LABOR_ITEM_POWER_GAS_REQUEST",
  POST_CLAIM_IN_HOUSE_LABOR_ITEM_POWER_GAS_SUCCESS: "POST_CLAIM_IN_HOUSE_LABOR_ITEM_POWER_GAS_SUCCESS",
  POST_CLAIM_VEHICLE_ITEM_POWER_GAS_REQUEST: "POST_CLAIM_VEHICLE_ITEM_POWER_GAS_REQUEST",
  POST_CLAIM_VEHICLE_ITEM_POWER_GAS_SUCCESS: "POST_CLAIM_VEHICLE_ITEM_POWER_GAS_SUCCESS",
  POST_CLAIM_CONTRACTOR_ITEM_POWER_GAS_REQUEST: "POST_CLAIM_CONTRACTOR_ITEM_POWER_GAS_REQUEST",
  POST_CLAIM_CONTRACTOR_ITEM_POWER_GAS_SUCCESS: "POST_CLAIM_CONTRACTOR_ITEM_POWER_GAS_SUCCESS",
  POST_CLAIM_LOSS_OF_GAS_ITEM_POWER_GAS_REQUEST: "POST_CLAIM_LOSS_OF_GAS_ITEM_POWER_GAS_REQUEST",
  POST_CLAIM_LOSS_OF_GAS_ITEM_POWER_GAS_SUCCESS: "POST_CLAIM_LOSS_OF_GAS_ITEM_POWER_GAS_SUCCESS",
  DELETE_CLAIM_JOB_COSTING_MATERIAL_ITEM_POWER_GAS_REQUEST: "DELETE_CLAIM_JOB_COSTING_MATERIAL_ITEM_POWER_GAS_REQUEST",
  DELETE_CLAIM_JOB_COSTING_MATERIAL_ITEM_POWER_GAS_SUCCESS: "DELETE_CLAIM_JOB_COSTING_MATERIAL_ITEM_POWER_GAS_SUCCESS",
  DELETE_CLAIM_JOB_COSTING_IN_HOUSE_LABOR_ITEM_POWER_GAS_REQUEST:
    "DELETE_CLAIM_JOB_COSTING_IN_HOUSE_LABOR_ITEM_POWER_GAS_REQUEST",
  DELETE_CLAIM_JOB_COSTING_IN_HOUSE_LABOR_ITEM_POWER_GAS_SUCCESS:
    "DELETE_CLAIM_JOB_COSTING_IN_HOUSE_LABOR_ITEM_POWER_GAS_SUCCESS",
  DELETE_CLAIM_JOB_COSTING_VEHICLE_ITEM_POWER_GAS_REQUEST: "DELETE_CLAIM_JOB_COSTING_VEHICLE_ITEM_POWER_GAS_REQUEST",
  DELETE_CLAIM_JOB_COSTING_VEHICLE_ITEM_POWER_GAS_SUCCESS: "DELETE_CLAIM_JOB_COSTING_VEHICLE_ITEM_POWER_GAS_SUCCESS",
  DELETE_CLAIM_JOB_COSTING_CONTRACTOR_ITEM_POWER_GAS_REQUEST:
    "DELETE_CLAIM_JOB_COSTING_CONTRACTOR_ITEM_POWER_GAS_REQUEST",
  DELETE_CLAIM_JOB_COSTING_CONTRACTOR_ITEM_POWER_GAS_SUCCESS:
    "DELETE_CLAIM_JOB_COSTING_CONTRACTOR_ITEM_POWER_GAS_SUCCESS",
  DELETE_CLAIM_JOB_COSTING_LOSS_OF_GAS_ITEM_POWER_GAS_REQUEST:
    "DELETE_CLAIM_JOB_COSTING_LOSS_OF_GAS_ITEM_POWER_GAS_REQUEST",
  DELETE_CLAIM_JOB_COSTING_LOSS_OF_GAS_ITEM_POWER_GAS_SUCCESS:
    "DELETE_CLAIM_JOB_COSTING_LOSS_OF_GAS_ITEM_POWER_GAS_SUCCESS",
  UPLOAD_CLAIM_JOB_COSTING_SHEET_POWER_GAS_REQUEST: "UPLOAD_CLAIM_JOB_COSTING_SHEET_POWER_GAS_REQUEST",
  UPLOAD_CLAIM_JOB_COSTING_SHEET_POWER_GAS_SUCCESS: "UPLOAD_CLAIM_JOB_COSTING_SHEET_POWER_GAS_SUCCESS",
  GET_CLAIM_INVOICE_DOCUMENT_POWER_GAS_REQUEST: "GET_CLAIM_INVOICE_DOCUMENT_POWER_GAS_REQUEST",
  GET_CLAIM_INVOICE_DOCUMENT_POWER_GAS_SUCCESS: "GET_CLAIM_INVOICE_DOCUMENT_POWER_GAS_SUCCESS",
  UPLOAD_CLAIM_DOCUMENT_POWER_GAS_REQUEST: "UPLOAD_CLAIM_DOCUMENT_POWER_GAS_REQUEST",
  UPLOAD_CLAIM_DOCUMENT_POWER_GAS_SUCESS: "UPLOAD_CLAIM_DOCUMENT_POWER_GAS_SUCESS",
  GET_CLAIM_DOCUMENT_POWER_GAS_REQUEST: "GET_CLAIM_DOCUMENT_POWER_GAS_REQUEST",
  GET_CLAIM_DOCUMENT_POWER_GAS_SUCCESS: "GET_CLAIM_DOCUMENT_POWER_GAS_SUCCESS",
  GET_CLAIM_DOCUMENT_SCHEMA_REASON_POWER_GAS_REQUEST: "GET_CLAIM_DOCUMENT_SCHEMA_REASON_POWER_GAS_REQUEST",
  GET_CLAIM_DOCUMENT_SCHEMA_REASON_POWER_GAS_SUCCESS: "GET_CLAIM_DOCUMENT_SCHEMA_REASON_POWER_GAS_SUCCESS",
  ADD_CLAIM_DOCUMENT_SCHEMA_REASON_POWER_GAS_REQUEST: "ADD_CLAIM_DOCUMENT_SCHEMA_REASON_POWER_GAS_REQUEST",
  ADD_CLAIM_DOCUMENT_SCHEMA_REASON_POWER_GAS_SUCCESS: "ADD_CLAIM_DOCUMENT_SCHEMA_REASON_POWER_GAS_SUCCESS",
  EDIT_CLAIM_DOCUMENT_SCHEMA_REASON_POWER_GAS_REQUEST: "EDIT_CLAIM_DOCUMENT_SCHEMA_REASON_POWER_GAS_REQUEST",
  EDIT_CLAIM_DOCUMENT_SCHEMA_REASON_POWER_GAS_SUCCESS: "EDIT_CLAIM_DOCUMENT_SCHEMA_REASON_POWER_GAS_SUCCESS",
  REMOVE_CLAIM_DOCUMENT_SCHEMA_REASON_POWER_GAS_REQUEST: "REMOVE_CLAIM_DOCUMENT_SCHEMA_REASON_POWER_GAS_REQUEST",
  REMOVE_CLAIM_DOCUMENT_SCHEMA_REASON_POWER_GAS_SUCCESS: "REMOVE_CLAIM_DOCUMENT_SCHEMA_REASON_POWER_GAS_SUCCESS",

  GET_CLAIM_DOCUMENT_SCHEMA_CONTACTS_POWER_GAS_REQUEST: "GET_CLAIM_DOCUMENT_SCHEMA_CONTACTS_POWER_GAS_REQUEST",
  GET_CLAIM_DOCUMENT_SCHEMA_CONTACTS_POWER_GAS_SUCCESS: "GET_CLAIM_DOCUMENT_SCHEMA_CONTACTS_POWER_GAS_SUCCESS",
  GET_CLAIM_DOCUMENT_SCHEMA_LAST_SENTENCE_POWER_GAS_REQUEST:
    "GET_CLAIM_DOCUMENT_SCHEMA_LAST_SENTENCE_POWER_GAS_REQUEST",
  GET_CLAIM_DOCUMENT_SCHEMA_LAST_SENTENCE_POWER_GAS_SUCCESS:
    "GET_CLAIM_DOCUMENT_SCHEMA_LAST_SENTENCE_POWER_GAS_SUCCESS",
  GET_CLAIM_ATTACHMENT_SCHEMA_IMAGE_CATEGORY_POWER_GAS_REQUEST:
    "GET_CLAIM_ATTACHMENT_SCHEMA_IMAGE_CATEGORY_POWER_GAS_REQUEST",
  GET_CLAIM_ATTACHMENT_SCHEMA_IMAGE_CATEGORY_POWER_GAS_SUCCESS:
    "GET_CLAIM_ATTACHMENT_SCHEMA_IMAGE_CATEGORY_POWER_GAS_SUCCESS",
  GET_CLAIM_ATTACHMENT_SCHEMA_VIDEO_CATEGORY_POWER_GAS_REQUEST:
    "GET_CLAIM_ATTACHMENT_SCHEMA_VIDEO_CATEGORY_POWER_GAS_REQUEST",
  GET_CLAIM_ATTACHMENT_SCHEMA_VIDEO_CATEGORY_POWER_GAS_SUCCESS:
    "GET_CLAIM_ATTACHMENT_SCHEMA_VIDEO_CATEGORY_POWER_GAS_SUCCESS",
  GET_CLAIM_ATTACHMENT_SCHEMA_DOCUMENT_CATEGORY_POWER_GAS_REQUEST:
    "GET_CLAIM_ATTACHMENT_SCHEMA_DOCUMENT_CATEGORY_POWER_GAS_REQUEST",
  GET_CLAIM_ATTACHMENT_SCHEMA_DOCUMENT_CATEGORY_POWER_GAS_SUCCESS:
    "GET_CLAIM_ATTACHMENT_SCHEMA_DOCUMENT_CATEGORY_POWER_GAS_SUCCESS",
  GET_CLAIM_NARRATIVE_CREATOR_DOCUMENT_POWER_GAS_REQUEST: "GET_CLAIM_NARRATIVE_CREATOR_DOCUMENT_POWER_GAS_REQUEST",
  GET_CLAIM_NARRATIVE_CREATOR_DOCUMENT_POWER_GAS_SUCCESS: "GET_CLAIM_NARRATIVE_CREATOR_DOCUMENT_POWER_GAS_SUCCESS",
  GET_CLAIM_INTENT_TO_BILL_DOCUMENT_POWER_GAS_REQUEST: "GET_CLAIM_INTENT_TO_BILL_DOCUMENT_POWER_GAS_REQUEST",
  GET_CLAIM_INTENT_TO_BILL_DOCUMENT_POWER_GAS_SUCCESS: "GET_CLAIM_INTENT_TO_BILL_DOCUMENT_POWER_GAS_SUCCESS",
  GET_CLAIM_PROMISSORY_DOCUMENT_POWER_GAS_REQUEST: "GET_CLAIM_PROMISSORY_DOCUMENT_POWER_GAS_REQUEST",
  GET_CLAIM_PROMISSORY_DOCUMENT_POWER_GAS_SUCCESS: "GET_CLAIM_PROMISSORY_DOCUMENT_POWER_GAS_SUCCESS",
  GET_CLAIM_LEGAL_FINAL_NOTICE_DOCUMENT_POWER_GAS_REQUEST: "GET_CLAIM_LEGAL_FINAL_NOTICE_DOCUMENT_POWER_GAS_REQUEST",
  GET_CLAIM_LEGAL_FINAL_NOTICE_DOCUMENT_POWER_GAS_SUCCESS: "GET_CLAIM_LEGAL_FINAL_NOTICE_DOCUMENT_POWER_GAS_SUCCESS",
  GET_CLAIM_FINAL_NOTICE_DOCUMENT_POWER_GAS_REQUEST: "GET_CLAIM_FINAL_NOTICE_DOCUMENT_POWER_GAS_REQUEST",
  GET_CLAIM_FINAL_NOTICE_DOCUMENT_POWER_GAS_SUCCESS: "GET_CLAIM_FINAL_NOTICE_DOCUMENT_POWER_GAS_SUCCESS",
  GET_CLAIM_RELEASE_LETTER_DOCUMENT_POWER_GAS_REQUEST: "GET_CLAIM_RELEASE_LETTER_DOCUMENT_POWER_GAS_REQUEST",
  GET_CLAIM_RELEASE_LETTER_DOCUMENT_POWER_GAS_SUCCESS: "GET_CLAIM_RELEASE_LETTER_DOCUMENT_POWER_GAS_SUCCESS",
  GET_CLAIM_SECOND_NOTICE_DOCUMENT_POWER_GAS_REQUEST: "GET_CLAIM_SECOND_NOTICE_DOCUMENT_POWER_GAS_REQUEST",
  GET_CLAIM_SECOND_NOTICE_DOCUMENT_POWER_GAS_SUCCESS: "GET_CLAIM_SECOND_NOTICE_DOCUMENT_POWER_GAS_SUCCESS",
  REMOVE_LEDGER_POWER_GAS_REQUEST: "REMOVE_LEDGER_POWER_GAS_REQUEST",
  REMOVE_LEDGER_POWER_GAS_SUCCESS: "REMOVE_LEDGER_POWER_GAS_SUCCESS",
  UPDATE_LEDGER_POWER_GAS_REQUEST: "UPDATE_LEDGER_POWER_GAS_REQUEST",
  UPDATE_LEDGER_POWER_GAS_SUCCESS: "UPDATE_LEDGER_POWER_GAS_SUCCESS",
  REMOVE_STATUS_NOTE_POWER_GAS_REQUEST: "REMOVE_STATUS_NOTE_POWER_GAS_REQUEST",
  REMOVE_STATUS_NOTE_POWER_GAS_SUCCESS: "REMOVE_STATUS_NOTE_POWER_GAS_SUCCESS",

  GET_CLAIM_PAYMENT_RECEIPT_DOCUMENT_POWER_GAS_REQUEST: "GET_CLAIM_PAYMENT_RECEIPT_DOCUMENT_POWER_GAS_REQUEST",
  GET_CLAIM_PAYMENT_RECEIPT_DOCUMENT_POWER_GAS_SUCCESS: "GET_CLAIM_PAYMENT_RECEIPT_DOCUMENT_POWER_GAS_SUCCESS",
  GET_CLAIM_BALANCE_OWED_DOCUMENT_POWER_GAS_REQUEST: "GET_CLAIM_BALANCE_OWED_DOCUMENT_POWER_GAS_REQUEST",
  GET_CLAIM_BALANCE_OWED_DOCUMENT_POWER_GAS_SUCCESS: "GET_CLAIM_BALANCE_OWED_DOCUMENT_POWER_GAS_SUCCESS",
  SORT_ATTACHMENTS_POWER_GAS_CLAIM_REQUEST: "SORT_ATTACHMENTS_POWER_GAS_CLAIM_REQUEST",
  SORT_ATTACHMENTS_POWER_GAS_CLAIM_SUCCESS: "SORT_ATTACHMENTS_POWER_GAS_CLAIM_SUCCESS",
  UPDATE_CATEGORY_ATTACHMENTS_POWER_GAS_CLAIM_REQUEST: "UPDATE_CATEGORY_ATTACHMENTS_POWER_GAS_CLAIM_REQUEST",
  UPDATE_CATEGORY_ATTACHMENTS_POWER_GAS_CLAIM_SUCCESS: "UPDATE_CATEGORY_ATTACHMENTS_POWER_GAS_CLAIM_SUCCESS",
  POWER_GAS_CLAIM_CLEAR: "POWER_GAS_CLAIM_CLEAR",
  SAVE_POWER_GAS_COLUMN_FILTER_REQUEST: "SAVE_POWER_GAS_COLUMN_FILTER_REQUEST",
  DELETE_ACTION_NOTE_POWER_GAS_REQUEST: "DELETE_ACTION_NOTE_POWER_GAS_REQUEST",
  GET_DAMAGING_PARTIES_POWER_GAS_REQUEST: "GET_DAMAGING_PARTIES_POWER_GAS_REQUEST",
  GET_DAMAGING_PARTIES_POWER_GAS_SUCCESS: "GET_DAMAGING_PARTIES_POWER_GAS_SUCCESS",
  GET_DAMAGING_PARTIES_POWER_GAS_FAILURE: "GET_DAMAGING_PARTIES_POWER_GAS_FAILURE",

  ADD_DAMAGING_PARTY_POWER_GAS_REQUEST: "ADD_DAMAGING_PARTY_POWER_GAS_REQUEST",
  ADD_DAMAGING_PARTY_POWER_GAS_SUCCESS: "ADD_DAMAGING_PARTY_POWER_GAS_SUCCESS",
  ADD_DAMAGING_PARTY_POWER_GAS_FAILURE: "ADD_DAMAGING_PARTY_POWER_GAS_FAILURE",
  SEARCH_DAMAGING_PARTIES_POWER_GAS_REQUEST: "SEARCH_DAMAGING_PARTIES_POWER_GAS_REQUEST",
  SEARCH_DAMAGING_PARTIES_POWER_GAS_SUCCESS: "SEARCH_DAMAGING_PARTIES_POWER_GAS_SUCCESS",
  SEARCH_DAMAGING_PARTIES_POWER_GAS_FAILURE: "SEARCH_DAMAGING_PARTIES_POWER_GAS_FAILURE",
  CLEAR_DAMAGING_PARTIES_SEARCH_RESULTS: "CLEAR_DAMAGING_PARTIES_SEARCH_RESULTS",
};
