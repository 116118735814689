// import { createMuiTheme } from "@material-ui/core/styles";
import {  createTheme } from "@material-ui/core/styles";


const themeDark = createTheme({
  mode: "dark", // v5
  palette: {
    type: "dark", // v4
    primary: {
      main: "#8bc34a",
    },
    secondary: {
      main: "#ffcc00",
    },
    background: {
      default: "#3b3b3b",
    },
    text: {
      primary: "#ffffff",
    },
    WhitePrg: {
      main: "#ffffff",
    },
    OffWhitePrg: {
      main: "#f5f5f5",
    },
    BodyWhitePrg: {
      main: "#fafafa",
    },
    BlackPrg: {
      main: "#000000",
    },
    OverlayPrg: {
      main: "#0000003b",
    },
    GrayPrg: {
      main: "#636363",
    },
    HeaderGrayPrg: {
      main: "#636363",
    },
    LightGrayPrg: {
      main: "#ffffff",
      secondary: "#727272",
    },
    DarkGrayPrg: {
      main: "#636363",
    },
    GreenPrg: {
      main: "#8bc34a",
      secondary: "#9fe055",
      contrast: "#444444",
    },
    HoverGreen: {
      main: "#e5efda",
      contrast: "#6b6b6b",
    },
    LightGreenPrg: {
      main: "#92bb6a",
      contrast: "#424242",
    },
    DarkGreenPrg: {
      main: "#6f9c3b",
      contrast: "#282828",
    },
    RedPrg: {
      light: "#E6717C",
      lightContrast: "#2e2e2e",
      main: "#dc3545",
      dark: "#841F29",
    },
    YellowPrg: {
      main: "#ffcc00",
      contrast: "#3e3e3e",
    },
    Blue: {
      main: "#2f4073",
    },
    Teal: {
      main: "#04bf7d",
      contrast: "#3c3c3c",
    },
  },
  typography: {
    allVariants: {
      color: 'white'
    }
  },
  components: {
    // Name of the component
    MuiDataGrid: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          color: '#fff',
        },
      },
    },
  },
  overrides: {
    body:{
      backgroundColor: '#3b3b3b !important'
    },
    MuiAlert: {
      root: {
        backgroundColor: "initial !important",
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "none !important",
      },
    },
    MuiDropzoneArea: {
      root: {
        backgroundColor: "#63636300 !important",
      },
    },
    MuiFormHelperText: {
      root: {
        color: "#ffffff",
      },
    },
    MuiChip: {
      root: {
        height: "25px",
      },
      deleteIcon: {
        color: "#636363",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "#63636300 !important",
      },
    },
    MuiFormControl: {
      root: {
        backgroundColor: "#63636300 !important",
      },
    },
    MuiSnackbarContent: {
      root: { color: "#f5f5f5 !important" },
    },
    MUIRichTextEditor: {
      root: {
        width: "100%",
      },
      editor: {
        borderBottom: "1px solid gray",
        minHeight: "2rem",
        color: "black",
        fontSize: "14px",
      },
    },
    MuiAutocomplete: {
      root: {
        color: "#8bc34a"
      },
      inputRoot: {
        backgroundColor: "transparent ",
        color: "#fff!important"
      },
      "&label": {
        color: "#fff"
      },
      "&.MuiFormLabel-root.MuiInputLabel-root": {
        color: "#fff"
      }
    },
    MuiInputLabelOutline: {
      color: "#fff"
    },
    MuiInputLabel: {
      root: {
        color: "#fff!important"
      }
    },
    MuiList: {
      root: { backgroundColor: "#505050 " },
      padding: {
        backgroundColor: "#505050 !important",
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "#505050 !important",
        color: "#fff!important",
      },
    },
    MuiAlert: {
      root: {
        backgroundColor: "#505050 !important",
      }
    },
    MuiFormLabel: {
      root: {
        color: "#fff!important",
        textShadow: '0 3px 5px #00000082',
        borderEadius: '5px',
        "&.Mui-disabled": {
          color: "rgba(255, 255, 255, 0.5)!important",
        },
      },
    },
    MuiTab: {
      textColorPrimary: {
        color: "#ffffff !important",
      },
    },
    MuiInputBase: {
      root: {
        color: "#ffffff",
        backgroundColor: "#636363 !important",
        borderRadius: "4px",
        outlined: {
          color: "#ffffff",
        },
      },
      outlined: {
        color: "#ffffff",
      },
      input: {
        color: "#fff!important",
        // paddingTop:'10px !important',
        // paddingBottom:'7px !important',
        "&.Mui-disabled": {
          color: "rgba(255, 255, 255, 0.5)!important",
          '-webkit-text-fill-color': `rgba(255,255,255,0.5)!important`,
          cursor: "not-allowed",
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        color: "#ffffff",
      },
    },
    MuiTableCell: {
      root: {
        color: "#ffffff !important",
      },
    },
    MuiListItemText: {
      primary: {
        color: "#ffffff !important",
      },
    },
    MuiSelect: {
      select: {
        color: "#ffffff !important",
      },
      icon: {
        color: "#ffffff !important",
      },
    },
    MuiIconButton: {
      root: {
        color: "#ffffff",
        "&.Mui-disabled": {
          color: "rgba(255, 255, 255, 0.5)",
        },
      },
    },
    MuiSvgIcon: {
      root: {
        //color: "#ffffff ",
      },
      fontSizeLarge: {
        color: "#ffffff !important",
      },
    },
    MuiRadio: {
      root: {
        color: "#8bc34a !important",
      },
    },
    // MUI Button Component
    MuiButtonBase: {
      root: {
        color: "#ffffff!important",
        "&:hover": {
          color: "#8bc34a",
        },
        "&.Mui-disabled": {
          color: "rgba(255, 255, 255, 0.5)!important",
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: "#fff",
      },
      outlined: {
        color: "#ffffff",
      },
      "&.MuiInputLabel-root": {
        color: "#fff"
      }
    },
    MuiTypography: {
      root: {
        color: "#ffffff!important",
      }
    },
    MuiButton: {
      root: {
        //backgroundColor: "transparent !important",
        boxShadow: "none !important",
        outlined: {
          border: "1px solid rgb(255 255 255 / 23%)",
        },
        contained: {
          backgroundColor: "#636363 !important",
          boxShadow: "none !important",
          "&:hover": {
            backgroundColor: "#505050 !important",
          },
        },
      },
    },
    MuiFormGroup: {
      root: {
        padding: "5px",
      },
    },
    MuiSwitch: {
      colorSecondary: {
        color: "#ffcc00 !important",
      },
    },
    MuiGrid: {
      root: {
        color: "#ffffff !important",

        "& .MuiDataGrid-root": {
          color: "#ffffff !important",
          "& .MuiTablePagination-root": {
            color: "#ffffff !important",
          },
          "& .MuiDataGrid-row--editing": {
            "& .MuiDataGrid-cell": {
              backgroundColor: "#3b3b3b",
            },
          },
          "& .MuiDataGrid-main": {
            color: "#ffffff !important",
            "& .MuiDataGrid-columnHeaderTitle": {
              color: "#ffffff !important",
            },
          },
        },

        "& .MuiDataGrid-row": {
          "&:nth-child(odd)": {
            backgroundColor: "#636363 !important",
          },
        },
        "& .MuiDataGrid-cell--editing": {
          backgroundColor: "#636363 !important",
        },
        "& .MuiDataGrid-booleanCell": {
          color: "#ffffff !important",

          "& .MuiDataGrid-root": {
            color: "#ffffff !important",
            "& .MuiDataGrid-row--editing": {
              "& .MuiDataGrid-cell": {
                backgroundColor: "#3b3b3b",
              },
            },
            "& .MuiDataGrid-main": {
              color: "#ffffff !important",
            },
          },

          "& .MuiDataGrid-row": {
            "&:nth-child(odd)": {
              backgroundColor: "#636363 !important",
            },
          },
          "& .MuiDataGrid-cell--editing": {
            backgroundColor: "#636363 !important",
          },
          "& .MuiDataGrid-booleanCell": {
            color: "#ffffff !important",
          },
          "& .MuiTablePagination-root": {
            color: "#ffffff !important",
          },
        },
      },
    }
  },
});

export default themeDark;
