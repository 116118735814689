import { getWsBase } from "./config";
import { useDispatch } from "react-redux";
import { telecomDispatchActions, powerGasDispatchActions, jointClaimsQueueActions } from "_actions";
import WebSocket from "isomorphic-ws";

export let client = null;
let confirmDispatchCallback = null;
let attachmentColumnsCallback = null;
let recoveryAgentsCallback = null;
let claimAssistantsCallback = null;
let roundRobinBucketsCallback = null;
let recoveryAgentsForBucketCallback = {};
let claimAssistantsForBucketCallback = {};
let recoveryTeamsCallback = null;
let recoveryAgentsForTeamCallback = {};
let agentStatsCallback = {};
let assistantStatsCallback = {};
let preLegalClaimsCallback = null;
let processLegalClaimsCallback = null;
let managementTeamsCallback = null;
let internalActiveUsersCallback = null;
let entityLocateComapniesCallback = null;

export const UseWebSocket = () => {
  const dispatch = useDispatch();

  const serialize = (data) => {
    return JSON.stringify(data);
  };

  if (client === null) client = new WebSocket(getWsBase());
  const initWebSocket = () => {
    if (client.readyState === WebSocket.CLOSED || client.readyState === WebSocket.CLOSING)
      client = new WebSocket(getWsBase());

    if (client.readyState === WebSocket.OPEN) client.close();

    client.onopen = () => {
      console.log("opened socket");

      setInterval(() => {
        if (client.lastMessage !== undefined && Date.now() - client.lastMessage > 15000) client.close();
      }, 15000);
    };

    client.onmessage = async (message) => {
      const deserialized = JSON.parse(message.data);
      client.lastMessage = Date.now();
      switch (deserialized.type) {
        case "whois":
          // Example: Read a specific cookie by name
          function getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(";").shift();
          }

          const token = getCookie("token");
          const assumingRole = getCookie("assumingRole");
          if (token === null || token === undefined) {
            return;
          }
          client.send(serialize({ type: "whois", message: token, assumingRole: assumingRole === "true" }));
          break;
        case "getDispatch":
          if (deserialized.db === "dbClaim") {
            dispatch(telecomDispatchActions.updateDispatchTimer(deserialized.timers));
            dispatch(telecomDispatchActions.updateDispatches(deserialized.dispatches));
          }
          if (deserialized.db === "dbPowGas") {
            dispatch(powerGasDispatchActions.updateDispatchTimer(deserialized.timers));
            dispatch(powerGasDispatchActions.updateDispatches(deserialized.dispatches));
          }
          break;
        case "updateColor":
          if (deserialized.db === "dbClaim")
            dispatch(
              telecomDispatchActions.updateDispatchTimerColor({
                lobid: 1,
                claimId: deserialized.claimId,
                color: deserialized.color,
              })
            );
          else if (deserialized.db === "dbPowGas")
            //@TODO: updateDispatchTimerColor for PowGas needs to be created
            dispatch(
              powerGasDispatchActions.updateDispatchTimerColor({
                lobid: 2,
                claimId: deserialized.claimId,
                color: deserialized.color,
              })
            );
          break;
        case "updatePaused":
          if (deserialized.db === "dbClaim")
            dispatch(
              telecomDispatchActions.updateDispatchTimerPause({
                lobid: 1,
                claimId: deserialized.claimId,
                pause: deserialized.paused,
              })
            );
          else if (deserialized.db === "dbPowGas")
            //@TODO: updateDispatchTimerPause for PowGas needs to be created
            dispatch(
              powerGasDispatchActions.updateDispatchTimerPause({
                lobid: 2,
                claimId: deserialized.claimId,
                pause: deserialized.paused,
              })
            );
          break;
        case "claimsQueue":
          dispatch(jointClaimsQueueActions.updateClaimsQueue(deserialized.queues, deserialized.roleId));
          break;
        case "dispatchConfirmed":
          dispatch(telecomDispatchActions.confirmDispatch(deserialized.claimId));
          if (confirmDispatchCallback) {
            confirmDispatchCallback();
            confirmDispatchCallback = null;
          }
          break;
        case "getAttachmentColumns":
          if (attachmentColumnsCallback) {
            attachmentColumnsCallback(deserialized.columns);
            attachmentColumnsCallback = null;
          }
          break;
        case "getRecoveryAgents":
          if (recoveryAgentsCallback) {
            recoveryAgentsCallback(deserialized.agents);
            recoveryAgentsCallback = null;
          }
          break;
        case "getClaimAssistants":
          if (claimAssistantsCallback) {
            claimAssistantsCallback(deserialized.agents);
            claimAssistantsCallback = null;
          }
          break;
        case "getRoundRobinBuckets":
          if (roundRobinBucketsCallback) {
            roundRobinBucketsCallback(deserialized.buckets);
            roundRobinBucketsCallback = null;
          }
          break;
        case "getRecoveryAgentsForBucket":
          if (recoveryAgentsForBucketCallback[deserialized.bucket_node_id]) {
            recoveryAgentsForBucketCallback[deserialized.bucket_node_id](deserialized.agents);
            recoveryAgentsForBucketCallback[deserialized.bucket_node_id] = null;
          }
          break;
        case "getClaimAssistantsForBucket":
          if (claimAssistantsForBucketCallback[deserialized.bucket_node_id]) {
            claimAssistantsForBucketCallback[deserialized.bucket_node_id](deserialized.agents);
            claimAssistantsForBucketCallback[deserialized.bucket_node_id] = null;
          }
          break;
        case "getRecoveryTeams":
          if (recoveryTeamsCallback) {
            recoveryTeamsCallback(deserialized.teams);
            recoveryTeamsCallback = null;
          }
          break;
        case "getRecoveryAgentsForTeam":
          if (recoveryAgentsForTeamCallback[deserialized.team_node_id]) {
            recoveryAgentsForTeamCallback[deserialized.team_node_id](deserialized.agents);
            recoveryAgentsForTeamCallback[deserialized.team_node_id] = null;
          }
          break;
        case "getStatsForAgent":
          if (agentStatsCallback[deserialized.agent_node_id]) {
            agentStatsCallback[deserialized.agent_node_id](deserialized.stats);
            agentStatsCallback[deserialized.agent_node_id] = null;
          }
          break;
        case "getStatsForAssistant":
          if (assistantStatsCallback[deserialized.agent_node_id]) {
            assistantStatsCallback[deserialized.agent_node_id](deserialized.stats);
            assistantStatsCallback[deserialized.agent_node_id] = null;
          }
          break;
        case "getPreLegalClaims":
          if (preLegalClaimsCallback) {
            preLegalClaimsCallback(deserialized.claims);
            preLegalClaimsCallback = null;
          }
          break;
        case "processLegalClaims":
          if (processLegalClaimsCallback) {
            processLegalClaimsCallback(deserialized.claims);
            processLegalClaimsCallback = null;
          }
          break;
        case "getManagementTeams":
          if (getManagementTeams) {
            managementTeamsCallback(deserialized.teams);
            managementTeamsCallback = null;
          }
          break;
        case "getInternalActiveUsers":
          if (internalActiveUsersCallback) {
            internalActiveUsersCallback(deserialized.users);
            internalActiveUsersCallback = null;
          }
          break;
        case "getEntityLocateCompanies":
          if (entityLocateComapniesCallback) {
            entityLocateComapniesCallback(deserialized.results);
            entityLocateComapniesCallback = null;
          }
          break;
      }
    };

    client.onclose = (reason) => {
      if (process.env.REACT_APP_API === "apptest" || process.env.REACT_APP_API === "dev")
        console.log("closed socket", reason);
      setTimeout(() => {
        initWebSocket();
      }, 1000);
    };
  };

  const getClaimsQueue = () => {
    if (client.readyState === WebSocket.OPEN) client.send(serialize({ type: "claimsQueue" }));
  };

  const saveStatusNoteHighlight = (id, Hlight, LOBID) => {
    if (client.readyState === WebSocket.OPEN) client.send(serialize({ type: "statusNoteHlight", Hlight, id, LOBID }));
  };

  // Currently telecom only
  const confirmDispatch = (claimId, cb) => {
    if (client.readyState === WebSocket.OPEN) {
      client.send(serialize({ type: "confirmDispatch", claimId }));
      confirmDispatchCallback = cb;
    }
  };

  const saveAttachmentColumns = (columns) => {
    if (client.readyState === WebSocket.OPEN) client.send(serialize({ type: "saveAttachmentColumns", columns }));
  };

  const getAttachmentColumns = (setColumnFilters) => {
    if (client.readyState === WebSocket.OPEN) {
      client.send(serialize({ type: "getAttachmentColumns" }));
      attachmentColumnsCallback = (columns) => {
        const filterArr = Object.keys(columns).map((key) => {
          return { [key]: columns[key] };
        });
        setColumnFilters(filterArr);
      };
    }
  };

  const getRecoveryAgents = (setAgents, locateOnly = false) => {
    if (client.readyState === WebSocket.OPEN) {
      client.send(serialize({ type: "getRecoveryAgents", locateOnly }));
      recoveryAgentsCallback = (agents) => {
        setAgents(agents);
      };
    }
  };
  const getClaimAssistants = (setAgents) => {
    if (client.readyState === WebSocket.OPEN) {
      client.send(serialize({ type: "getClaimAssistants" }));
      claimAssistantsCallback = (agents) => {
        setAgents(agents);
      };
    }
  };

  const getRoundRobinBuckets = (setBuckets) => {
    if (client.readyState === WebSocket.OPEN) {
      client.send(serialize({ type: "getRoundRobinBuckets" }));
      roundRobinBucketsCallback = (buckets) => {
        setBuckets(buckets);
      };
    }
  };

  const addAgentToBucket = (agent_node_id, bucket_node_id, setBucketAgents, subType) => {
    if (client.readyState === WebSocket.OPEN) {
      client.send(serialize({ type: "addAgentToBucket", agent_node_id, bucket_node_id, subType }));
      if (subType === "recovery")
        recoveryAgentsForBucketCallback[bucket_node_id] = (agents) => {
          setBucketAgents((buckets) => {
            return { ...buckets, [`${bucket_node_id}`]: agents };
          });
        };
      if (subType === "claims")
        claimAssistantsForBucketCallback[bucket_node_id] = (agents) => {
          setBucketAgents((buckets) => {
            return { ...buckets, [`${bucket_node_id}`]: agents };
          });
        };
    }
  };

  const removeAgentFromBucket = (edgeId, bucket_node_id, setBucketAgents, subType) => {
    if (client.readyState === WebSocket.OPEN) {
      client.send(serialize({ type: "removeAgentFromBucket", edgeId, bucket_node_id, subType }));
      if (subType === "recovery")
        recoveryAgentsForBucketCallback[bucket_node_id] = (agents) => {
          setBucketAgents((buckets) => {
            return { ...buckets, [`${bucket_node_id}`]: agents };
          });
        };
      if (subType === "claims")
        claimAssistantsForBucketCallback[bucket_node_id] = (agents) => {
          setBucketAgents((buckets) => {
            return { ...buckets, [`${bucket_node_id}`]: agents };
          });
        };
    }
  };

  const getRecoveryAgentsForBucket = (bucket_node_id, setBucketAgents, resolve) => {
    if (client.readyState === WebSocket.OPEN) {
      client.send(serialize({ type: "getRecoveryAgentsForBucket", bucket_node_id }));
      recoveryAgentsForBucketCallback[bucket_node_id] = (agents) => {
        setBucketAgents((buckets) => {
          return { ...buckets, [bucket_node_id]: agents || [] };
        });
        resolve();
      };
    }
  };

  const getClaimAssistantsForBucket = (bucket_node_id, setBucketAgents, resolve) => {
    if (client.readyState === WebSocket.OPEN) {
      client.send(serialize({ type: "getClaimAssistantsForBucket", bucket_node_id }));
      claimAssistantsForBucketCallback[bucket_node_id] = (agents) => {
        setBucketAgents((buckets) => {
          return { ...buckets, [bucket_node_id]: agents || [] };
        });
        resolve();
      };
    }
  };

  const updateRoundRobinSettings = (edgeId, settings) => {
    if (client.readyState === WebSocket.OPEN) {
      client.send(serialize({ type: "updateRoundRobinSettings", edgeId, settings }));
    }
  };

  const getRecoveryTeams = (setTeams) => {
    if (client.readyState === WebSocket.OPEN) {
      client.send(serialize({ type: "getRecoveryTeams" }));
      recoveryTeamsCallback = (teams) => {
        setTeams(teams);
      };
    }
  };

  const getRecoveryAgentsForTeam = (team_node_id, setTeamAgents, resolve) => {
    if (client.readyState === WebSocket.OPEN) {
      client.send(serialize({ type: "getRecoveryAgentsForTeam", team_node_id }));
      recoveryAgentsForTeamCallback[team_node_id] = (agents) => {
        setTeamAgents((teams) => {
          return { ...teams, [team_node_id]: agents || [] };
        });
        resolve();
      };
    }
  };

  const addAgentToTeam = (agent_node_id, team_node_id, startDate, setTeamAgents, cb) => {
    if (client.readyState === WebSocket.OPEN) {
      client.send(serialize({ type: "addAgentToTeam", agent_node_id, team_node_id, startDate }));
      recoveryAgentsForTeamCallback[team_node_id] = (agents) => {
        setTeamAgents((teams) => {
          return { ...teams, [team_node_id]: agents };
        });
        if (cb) cb();
      };
    }
  };

  const removeAgentFromTeam = (edgeId, team_node_id, endDate, setTeamAgents, cb) => {
    if (client.readyState === WebSocket.OPEN) {
      client.send(serialize({ type: "removeAgentFromTeam", edgeId, team_node_id, endDate }));
      recoveryAgentsForTeamCallback[team_node_id] = (agents) => {
        setTeamAgents((teams) => {
          return { ...teams, [team_node_id]: agents };
        });
        if (cb) cb();
      };
    }
  };

  const getStatsForAgent = (agent_node_id, setStats) => {
    if (client.readyState === WebSocket.OPEN) {
      client.send(serialize({ type: "getStatsForAgent", agent_node_id }));
      agentStatsCallback[agent_node_id] = (stats) => {
        setStats(stats);
      };
    }
  };

  const getStatsForAssistant = (agent_node_id, setStats) => {
    if (client.readyState === WebSocket.OPEN) {
      client.send(serialize({ type: "getStatsForAssistant", agent_node_id }));
      assistantStatsCallback[agent_node_id] = (stats) => {
        setStats(stats);
      };
    }
  };

  const updateTeamName = (value, team_node_id) => {
    if (client.readyState === WebSocket.OPEN) {
      client.send(serialize({ type: "updateTeamName", value, team_node_id }));
    }
  };

  const updateTeamCode = (value, team_node_id) => {
    if (client.readyState === WebSocket.OPEN) {
      client.send(serialize({ type: "updateTeamCode", value, team_node_id }));
    }
  };

  const addNewTeam = (setTeams, cb, TeamName, TeamCode) => {
    if (client.readyState === WebSocket.OPEN) {
      client.send(serialize({ type: "addNewTeam", TeamName, TeamCode }));
      recoveryTeamsCallback = (teams) => {
        cb();
        setTeams(teams);
      };
    }
  };

  const getPreLegalClaims = (setClaims, cb = null) => {
    if (client.readyState === WebSocket.OPEN) {
      client.send(serialize({ type: "getPreLegalClaims" }));
      preLegalClaimsCallback = (claims) => {
        setClaims(claims);
        if (cb) cb();
      };
    }
  };

  const processLegalClaims = (TelClaims, PgClaims, cb = null) => {
    if (client.readyState === WebSocket.OPEN) {
      client.send(serialize({ type: "processLegalClaims", TelClaims, PgClaims }));
      processLegalClaimsCallback = (claims) => {
        if (cb) cb();
      };
    }
  };

  const getManagementTeams = (setTeams) => {
    if (client.readyState === WebSocket.OPEN) {
      client.send(serialize({ type: "getManagementTeams" }));
      managementTeamsCallback = (teams) => {
        setTeams(teams);
      };
    }
  };

  const getInternalActiveUsers = (setUsers) => {
    if (client.readyState === WebSocket.OPEN) {
      client.send(serialize({ type: "getInternalActiveUsers" }));
      internalActiveUsersCallback = (users) => {
        setUsers(users);
      };
    }
  };

  const addMemberToManagementTeam = (memberNodeId, managerNodeId, setTeams) => {
    if (client.readyState === WebSocket.OPEN) {
      client.send(serialize({ type: "addMemberToManagementTeam", memberNodeId, managerNodeId }));
      managementTeamsCallback = (teams) => {
        setTeams(teams);
      };
    }
  };

  const removeMemberFromManagementTeam = (edgeId, setTeams) => {
    if (client.readyState === WebSocket.OPEN) {
      client.send(serialize({ type: "removeMemberFromManagementTeam", edgeId }));
      managementTeamsCallback = (teams) => {
        setTeams(teams);
      };
    }
  };

  const getEntityLocateCompanies = (setData) => {
    if (client.readyState === WebSocket.OPEN) {
      client.send(serialize({ type: "getEntityLocateCompanies" }));
      entityLocateComapniesCallback = (data) => {
        setData(data);
      };
    }
  };

  return {
    initWebSocket,
    getClaimsQueue,
    saveStatusNoteHighlight,
    confirmDispatch,
    saveAttachmentColumns,
    getAttachmentColumns,
    getRecoveryAgents,
    getRoundRobinBuckets,
    addAgentToBucket,
    getRecoveryAgentsForBucket,
    updateRoundRobinSettings,
    removeAgentFromBucket,
    getClaimAssistants,
    getClaimAssistantsForBucket,
    getRecoveryTeams,
    getRecoveryAgentsForTeam,
    addAgentToTeam,
    removeAgentFromTeam,
    getStatsForAgent,
    getStatsForAssistant,
    updateTeamName,
    updateTeamCode,
    addNewTeam,
    getPreLegalClaims,
    processLegalClaims,
    getManagementTeams,
    getInternalActiveUsers,
    addMemberToManagementTeam,
    removeMemberFromManagementTeam,
    getEntityLocateCompanies,
  };
};
