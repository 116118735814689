import { getApiBase } from "_helpers";
import { cookieService } from "_services";

const postError = (error, errorInfo, location, userId, timestamp, message) => {
  let body = {
    error,
    errorInfo,
    location,
    userId,
    timestamp,
    message,
  };
  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }
  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };
  let apiBase = getApiBase();

  return fetch(`${apiBase}/error`, parameters)
    .then((response) => {
      if (response.status !== 200) {
        throw new Error("Error failed to post");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

export const errorReportingService = { postError };
