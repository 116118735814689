import { put, call, all } from "redux-saga/effects";
import { buildAlertMessage } from "_utility";
import {
  alertConstants,
  authConstants,
  powerGasClaimConstants,
  adminJobCostingConstants,
  jointLedgerConstants,
} from "_constants";
import { jointLedgerService, powerGasClaimService } from "_services";
import { isEmpty } from "ramda";
import _getProperty from "lodash/get";

//Build filter params
let separator = "_|_";
let buildFilterQueryParams = (filterModal) => {
  if (!filterModal) return "";
  let { items, logicOperator } = filterModal;
  if (items.length < 1) return "";

  let itemsSanitized = items.reduce((prev, el) => {
    if (el.hasOwnProperty("value")) {
      return [...prev, el];
    }
    return prev;
  }, []);

  let buildString = itemsSanitized.reduce((prev, el) => {
    let field = encodeURIComponent(`${el["field"]}${separator}${el["id"]}`);
    let operator = encodeURIComponent(`${el["operator"]}${separator}${el["id"]}`);
    let value = encodeURIComponent(`${el["value"]}${separator}${el["id"]}`);

    let completeString = `&field=${field}&operator=${operator}&value=${value}`;

    return `${prev}${completeString}`;
  }, "");

  // Ensure the linkOperator is also encoded
  logicOperator = encodeURIComponent(logicOperator);

  return `${buildString}&linkOperator=${logicOperator}`;
}; // returns a string

export function* getAllClaimsPowerGasSaga({
  page,
  rowsPerPage,
  filterModal,
  search,
  searchField,
  searchFields,
  isDispatchAdmin,
  allClaimsCount,
  restrictedStatusIds,
}) {
  try {
    let filterParams = buildFilterQueryParams(filterModal);

    const response = yield call(
      powerGasClaimService.getAllClaims,
      page,
      rowsPerPage,
      filterParams,
      search,
      searchField,
      searchFields,
      isDispatchAdmin,
      allClaimsCount,
      restrictedStatusIds
    );

    yield put({
      type: powerGasClaimConstants.GET_ALL_CLAIMS_POWER_GAS_SUCCESS,
      payload: {
        page,
        claims: [...response["data"]],
        count: response["count"],
        columnFilters: response["columnFilters"],
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getMyClaimsPowerGasSaga({
  page,
  rowsPerPage,
  filterModal,
  search,
  searchField,
  searchFields,
  userId,
}) {
  try {
    let filterParams = buildFilterQueryParams(filterModal);

    const response = yield call(
      powerGasClaimService.getMyClaims,
      page,
      rowsPerPage,
      filterParams,
      search,
      searchField,
      searchFields,
      userId
    );

    yield put({
      type: powerGasClaimConstants.GET_MY_CLAIMS_POWER_GAS_SUCCESS,
      payload: {
        page,
        claims: [...response["data"]],
        count: response["count"],
        columnFilters: response["columnFilters"],
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getClaimByIdPowerGasSaga({ claimId, restrictedStatusIds }) {
  try {
    yield put({
      type: powerGasClaimConstants.GET_CLAIM_BY_ID_POWER_GAS_LOADING_TRUE,
    });

    const response = yield call(powerGasClaimService.getClaimById, claimId, restrictedStatusIds);

    yield put({
      type: powerGasClaimConstants.GET_CLAIM_BY_ID_POWER_GAS_SUCCESS,
      payload: {
        claimId,
        claim: { ...response },
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updatePowerGasClaimSaga({ claimId, payload, restrictedStatusIds }) {
  try {
    const response = yield call(powerGasClaimService.updateClaim, claimId, payload);

    if (response["status"] !== 200) {
      throw new Error("Error updating Claim");
    }

    const responseFetchUpdatedClaim = yield call(powerGasClaimService.getClaimById, claimId, restrictedStatusIds);

    yield put({
      type: powerGasClaimConstants.GET_CLAIM_BY_ID_POWER_GAS_SUCCESS,
      payload: {
        claimId,
        claim: { ...responseFetchUpdatedClaim },
      },
    });

    const responsHistory = yield call(powerGasClaimService.getClaimHistory, claimId);

    yield put({
      type: powerGasClaimConstants.GET_CLAIM_HISTORY_POWER_GAS_SUCCESS,
      payload: { data: [...responsHistory], claimId },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Claim updated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* deletePowerGasClaimSaga({
  claimId,
  currentQuery,
  page,
  rowsPerPage,
  filterModal,
  search,
  isDispatchAdmin,
  allClaimsCount,
  userId,
  restrictedStatusIds,
  trackChanges,
  data,
  callback,
  searchField,
  searchFields,
}) {
  try {
    const response = yield call(
      powerGasClaimService.deleteClaim,
      claimId,
      restrictedStatusIds,
      trackChanges,
      data,
      callback
    );

    if (response["status"] !== 201) {
      throw new Error("Error deleting Claim");
    }

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: `Claim ${claimId} deleted` },
    });

    // use current page,filter,search etc. to refresh data based on currentyQuery (all, myClaims, etc)

    let filterParams = buildFilterQueryParams(filterModal);

    switch (currentQuery) {
      case "all":
        const responseGetAllClaims = yield call(
          powerGasClaimService.getAllClaims,
          page,
          rowsPerPage,
          filterParams,
          search,
          searchField,
          searchFields,
          isDispatchAdmin,
          allClaimsCount,
          restrictedStatusIds
        );

        yield put({
          type: powerGasClaimConstants.GET_ALL_CLAIMS_POWER_GAS_SUCCESS,
          payload: {
            page,
            claims: [...responseGetAllClaims["data"]],
            count: responseGetAllClaims["count"],
          },
        });
        break;
      case "myClaims":
        const responseGetMyClaims = yield call(
          powerGasClaimService.getMyClaims,
          page,
          rowsPerPage,
          filterParams,
          search,
          userId
        );
        yield put({
          type: powerGasClaimConstants.GET_MY_CLAIMS_POWER_GAS_SUCCESS,
          payload: {
            page,
            claims: [...responseGetMyClaims["data"]],
            count: responseGetMyClaims["count"],
          },
        });
        break;
      case "myTeams":
        const responseGetMyTeamsClaims = yield call(
          powerGasClaimService.getMyTeamsClaims,
          page,
          rowsPerPage,
          filterParams,
          search,
          isDispatchAdmin,
          allClaimsCount,
          userId
        );
        yield put({
          type: powerGasClaimConstants.GET_MY_TEAMS_CLAIMS_POWER_GAS_SUCCESS,
          payload: {
            page,
            claims: [...responseGetMyTeamsClaims["data"]],
            count: responseGetMyTeamsClaims["count"],
          },
        });
        break;
      default:
        break;
    }
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getMyTeamsClaimsPowerGasSaga({
  page,
  rowsPerPage,
  filterModal,
  search,
  searchField,
  searchFields,
  isDispatchAdmin,
  allClaimsCount,
  userLogginIn,
}) {
  try {
    let filterParams = buildFilterQueryParams(filterModal);

    const response = yield call(
      powerGasClaimService.getMyTeamsClaims,
      page,
      rowsPerPage,
      filterParams,
      search,
      searchField,
      searchFields,
      isDispatchAdmin,
      allClaimsCount,
      userLogginIn
    );
    yield put({
      type: powerGasClaimConstants.GET_MY_TEAMS_CLAIMS_POWER_GAS_SUCCESS,
      payload: {
        page,
        claims: [...response["data"]],
        count: response["count"],
        columnFilters: response["columnFilters"],
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getDamagingPartiesSaga() {
  try {
    const response = yield call(powerGasClaimService.getDamagingParties);

    yield put({
      type: powerGasClaimConstants.GET_DAMAGING_PARTIES_POWER_GAS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* addDamagingPartySaga({ damagingName, data }) {
  try {
    // Trim the damaging name
    const trimmedName = damagingName.trim();

    // Make sure data also has trimmed damagingName
    const trimmedData = {
      ...data,
      damagingName: trimmedName,
    };

    const response = yield call(powerGasClaimService.addDamagingParty, trimmedName, trimmedData);

    yield put({
      type: powerGasClaimConstants.ADD_DAMAGING_PARTY_POWER_GAS_SUCCESS,
      payload: response,
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Damaging party added successfully" },
    });

    // Refresh the damaging parties list
    yield put({
      type: powerGasClaimConstants.GET_DAMAGING_PARTIES_POWER_GAS_REQUEST,
    });
  } catch (error) {
    console.error("Error adding damaging party:", error);

    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* searchDamagingPartiesSaga({ searchTerm }) {
  try {
    if (searchTerm && searchTerm.length >= 2) {
      console.log("Saga received search term:", searchTerm);

      // Trim the search term
      const trimmedTerm = searchTerm.trim();

      // Call the API
      const response = yield call(powerGasClaimService.searchDamagingParties, trimmedTerm);

      console.log("Saga received search results:", response);

      yield put({
        type: powerGasClaimConstants.SEARCH_DAMAGING_PARTIES_POWER_GAS_SUCCESS,
        payload: response || [],
      });
    } else {
      // Clear results if search term is too short
      yield put({
        type: powerGasClaimConstants.CLEAR_DAMAGING_PARTIES_SEARCH_RESULTS,
      });
    }
  } catch (error) {
    console.error("Error searching damaging parties:", error);

    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }

    yield put({
      type: powerGasClaimConstants.SEARCH_DAMAGING_PARTIES_POWER_GAS_FAILURE,
      payload: error.message,
    });

    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getSchemaEntityOptionsPowerGasSaga() {
  try {
    const response = yield call(powerGasClaimService.getSchemaEntityOptions);

    if (!Array.isArray(response)) {
      throw new Error("Error fetching entity options");
    }

    yield put({
      type: powerGasClaimConstants.GET_SCHEMA_ENTITY_OPTIONS_POWER_GAS_SUCCESS,
      payload: { options: [...response] },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getSchemaRdaOptionsPowerGasSaga({ entityId }) {
  try {
    const response = yield call(powerGasClaimService.getSchemaRdaOptions, entityId);

    if (!Array.isArray(response)) {
      throw new Error("Error fetching rda options");
    }

    yield put({
      type: powerGasClaimConstants.GET_SCHEMA_RDA_OPTIONS_POWER_GAS_SUCCESS,
      payload: { entityId, options: [...response] },
    });
    yield put({
      type: adminJobCostingConstants.GET_RDA_OPTIONS_SUCCESS,
      payload: { options: [...response] },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getCreateClaimFieldRulesPowGasSaga({ entityId }) {
  try {
    const response = yield call(powerGasClaimService.getCreateClaimFieldRules, entityId);
    if (!response || isEmpty(response)) {
      throw new Error("Error fetching field meta data");
    }

    yield put({
      type: powerGasClaimConstants.GET_CREATE_CLAIM_FIELD_RULES_POWER_GAS_SUCCESS,
      payload: { entityId, rules: { ...response } },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getSchemaFilterConditionsPowerGasSaga() {
  try {
    const response = yield call(powerGasClaimService.getSchemaFilterConditions);

    if (isEmpty(response) || !response) {
      throw new Error("Error fetching filter options");
    }
    yield put({
      type: powerGasClaimConstants.GET_SCHEMA_FILTER_OPTIONS_POWER_GAS_SUCCESS,
      payload: [...response],
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getFilterSetsPowerGasSaga() {
  try {
    const response = yield call(powerGasClaimService.getFilterSets);
    yield put({
      type: powerGasClaimConstants.GET_FILTER_SETS_POWER_GAS_SUCCESS,
      payload: [...response],
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* createFilterSetPowerGasSaga({ payload }) {
  try {
    let { filters, activeColumns } = payload;

    let filtersEncoded = JSON.stringify(filters);
    let activeColumnsEncoded = activeColumns.join(",");

    const createSetRespponse = yield call(powerGasClaimService.createFilterSet, {
      ...payload,
      filters: filtersEncoded,
      activeColumns: activeColumnsEncoded,
    });

    if (isEmpty(createSetRespponse)) {
      throw new Error("Error creating filter set");
    }

    const response = yield call(powerGasClaimService.getFilterSets);

    yield put({
      type: powerGasClaimConstants.GET_FILTER_SETS_POWER_GAS_SUCCESS,
      payload: [...response],
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "New filter set created" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateFilterSetPowerGasSaga({ id, payload }) {
  try {
    yield call(powerGasClaimService.updateFilterSet, id, payload);
    const response = yield call(powerGasClaimService.getFilterSets);

    yield put({
      type: powerGasClaimConstants.GET_FILTER_SETS_POWER_GAS_SUCCESS,
      payload: [...response],
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Filter Set Updated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* removeFilterSetPowerGasSaga({ id }) {
  try {
    yield call(powerGasClaimService.removeFilterSet, id);
    const response = yield call(powerGasClaimService.getFilterSets);

    yield put({
      type: powerGasClaimConstants.GET_FILTER_SETS_POWER_GAS_SUCCESS,
      payload: [...response],
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Filter Set Removed" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* duplicateFilterSetPowerGasSaga({ name, id }) {
  try {
    yield call(powerGasClaimService.duplicateFilterSet, id, name);
    const response = yield call(powerGasClaimService.getFilterSets);

    yield put({
      type: powerGasClaimConstants.GET_FILTER_SETS_POWER_GAS_SUCCESS,
      payload: [...response],
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Filter Set Duplicated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* uploadAttachmentPowerGasSaga({ payload: { files, ...payload }, claimId }) {
  try {
    const responses = yield all(files.map((image) => call(powerGasClaimService.uploadFile, image, payload)));

    if (isEmpty(responses)) {
      throw new Error("Error uploading files");
    }
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Upload Successful" },
    });

    //let statusMessage = buildAlertMessage("Uploads", responses);

    let startOffset = encodeURI(`PowerGas/claims/${claimId}`);

    const responseFetch = yield call(powerGasClaimService.getFiles, startOffset);

    yield put({
      type: powerGasClaimConstants.GET_CLAIM_ATTACHMENTS_POWER_GAS_SUCCESS,
      payload: { files: [...responseFetch], claimId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* saveRotationAttachmentPowerGasSaga({ payload }) {
  try {
    yield call(powerGasClaimService.handleSaveRotation, payload);

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Rotation Updated Successful" },
    });

    let startOffset = encodeURI(`PowerGas/claims/${payload.claimId}`);

    const responseFetch = yield call(powerGasClaimService.getFiles, startOffset);

    yield put({
      type: powerGasClaimConstants.GET_CLAIM_ATTACHMENTS_POWER_GAS_SUCCESS,
      payload: { files: [...responseFetch], claimId: payload.claimId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({ type: authConstants.LOGOUT });
    }
    yield put({ type: alertConstants.ERROR, payload: { message: error.message } });
  }
}

export function* getAttachmentsPowerGasSaga({ claimId }) {
  try {
    let startOffset = encodeURI(`PowerGas/claims/${claimId}`);

    const response = yield call(powerGasClaimService.getFiles, startOffset);

    yield put({
      type: powerGasClaimConstants.GET_CLAIM_ATTACHMENTS_POWER_GAS_SUCCESS,
      payload: { files: [...response], claimId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getSortOrderAttachmentsPowerGasSaga({ payload, claimId }) {
  try {
    yield call(powerGasClaimService.sortOrder, payload);

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Order Saved" },
    });

    let startOffset = encodeURI(`PowerGas/claims/${claimId}`);

    const response = yield call(powerGasClaimService.getFiles, startOffset);

    yield put({
      type: powerGasClaimConstants.GET_CLAIM_ATTACHMENTS_POWER_GAS_SUCCESS,
      payload: { files: [...response], claimId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updateCategoryAttachmentsPowerGasSaga({ payload, claimId }) {
  try {
    yield call(powerGasClaimService.updateCategory, payload);

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Categories Updated" },
    });

    let startOffset = encodeURI(`PowerGas/claims/${claimId}`);

    const response = yield call(powerGasClaimService.getFiles, startOffset);

    yield put({
      type: powerGasClaimConstants.GET_CLAIM_ATTACHMENTS_POWER_GAS_SUCCESS,
      payload: { files: [...response], claimId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* postPaymentPowerGasSaga({ claimId, payload }) {
  try {
    yield call(powerGasClaimService.postPayment, payload, claimId);
    const response = yield call(powerGasClaimService.getClaimById, claimId, []);
    yield put({
      type: powerGasClaimConstants.GET_CLAIM_BY_ID_POWER_GAS_SUCCESS,
      payload: {
        claimId,
        claim: { ...response },
      },
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Payment Posted" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getClaimHistoryPowerGasSaga({ claimId }) {
  try {
    const response = yield call(powerGasClaimService.getClaimHistory, claimId);

    yield put({
      type: powerGasClaimConstants.GET_CLAIM_HISTORY_POWER_GAS_SUCCESS,
      payload: { data: [...response], claimId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getClaimJobCostingInvoicePowerGasSaga({ claimId }) {
  try {
    const responseData = yield call(powerGasClaimService.getJobCostingInvoice, claimId);

    let { response } = responseData;

    yield put({
      type: powerGasClaimConstants.GET_JOB_COSTING_INVOICE_POWER_GAS_SUCCESS,
      payload: { claimId, data: { ...response } },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimInvoiceTimeStampPowerGasSaga({ claimId }) {
  try {
    const responseDataId = yield call(powerGasClaimService.claimInvoiceTimeStamp, claimId);

    let { invoiceId } = responseDataId;
    if (!invoiceId) return;
    const responseData = yield call(powerGasClaimService.getJobCostingInvoice, claimId);

    let { response } = responseData;

    yield put({
      type: powerGasClaimConstants.GET_JOB_COSTING_INVOICE_POWER_GAS_SUCCESS,
      payload: { claimId, data: { ...response } },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimPostMaterialPowerGasSaga({ payload, claimId, invoiceId }) {
  try {
    yield call(powerGasClaimService.postClaimMaterialItem, payload, claimId, invoiceId);
    const responseData = yield call(powerGasClaimService.getJobCostingInvoice, claimId);

    let { response } = responseData;

    yield put({
      type: powerGasClaimConstants.GET_JOB_COSTING_INVOICE_POWER_GAS_SUCCESS,
      payload: { claimId, data: { ...response } },
    });
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Success Material Item Created" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimPostInHouseLaborPowerGasSaga({ payload, claimId, invoiceId }) {
  try {
    yield call(powerGasClaimService.postClaimInHouseLaborItem, payload, claimId, invoiceId);
    const responseData = yield call(powerGasClaimService.getJobCostingInvoice, claimId);

    let { response } = responseData;

    yield put({
      type: powerGasClaimConstants.GET_JOB_COSTING_INVOICE_POWER_GAS_SUCCESS,
      payload: { claimId, data: { ...response } },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Success In-House Labor Item Created" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimPostVehicleItemPowerGasSaga({ payload, claimId, invoiceId }) {
  try {
    yield call(powerGasClaimService.postClaimVehicleItem, payload, claimId, invoiceId);
    const responseData = yield call(powerGasClaimService.getJobCostingInvoice, claimId);
    let { response } = responseData;
    yield put({
      type: powerGasClaimConstants.GET_JOB_COSTING_INVOICE_POWER_GAS_SUCCESS,
      payload: { claimId, data: { ...response } },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Success Vehicle Item Created" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimPostContractorItemPowerGasSaga({ payload, claimId, invoiceId }) {
  try {
    yield call(powerGasClaimService.postClaimContractorItem, payload, claimId, invoiceId);
    const responseData = yield call(powerGasClaimService.getJobCostingInvoice, claimId);
    let { response } = responseData;
    yield put({
      type: powerGasClaimConstants.GET_JOB_COSTING_INVOICE_POWER_GAS_SUCCESS,
      payload: { claimId, data: { ...response } },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Success Contractor Item Created" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimPostLossOfGasItemPowerGasSaga({ payload, claimId, invoiceId }) {
  try {
    yield call(powerGasClaimService.postClaimLossOfGasItem, payload, claimId, invoiceId);
    const responseData = yield call(powerGasClaimService.getJobCostingInvoice, claimId);
    let { response } = responseData;
    yield put({
      type: powerGasClaimConstants.GET_JOB_COSTING_INVOICE_POWER_GAS_SUCCESS,
      payload: { claimId, data: { ...response } },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Success Loss of Gas Item Created" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimJobCostingRemoveMaterialItemPowerGasSaga({ invoiceMaterialItemIds, invoiceId, claimId }) {
  try {
    let responses = yield all(
      invoiceMaterialItemIds.map((itemId) => call(powerGasClaimService.jobCostingRemoveMaterialItem, itemId, invoiceId))
    );
    if (isEmpty(responses)) {
      throw new Error("Error removing material items");
    }
    let statusMessage = buildAlertMessage("Material Items removed");

    const responseData = yield call(powerGasClaimService.getJobCostingInvoice, claimId);
    let { response } = responseData;
    yield put({
      type: powerGasClaimConstants.GET_JOB_COSTING_INVOICE_POWER_GAS_SUCCESS,
      payload: { claimId, data: { ...response } },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: {
        message: `${statusMessage}`,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimJobCostingRemoveInHouseLaborItemPowerGasSaga({ invoiceLaborItemIds, invoiceId, claimId }) {
  try {
    let responses = yield all(
      invoiceLaborItemIds.map((itemId) =>
        call(powerGasClaimService.jobCostingRemoveInHouseLaborItem, itemId, invoiceId)
      )
    );
    if (isEmpty(responses)) {
      throw new Error("Error removing labor items");
    }
    let statusMessage = buildAlertMessage("Labor Items removed");

    const responseData = yield call(powerGasClaimService.getJobCostingInvoice, claimId);
    let { response } = responseData;
    yield put({
      type: powerGasClaimConstants.GET_JOB_COSTING_INVOICE_POWER_GAS_SUCCESS,
      payload: { claimId, data: { ...response } },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: {
        message: `${statusMessage}`,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimJobCostingRemoveVehicleItemPowerGasSaga({ invoiceVehicleItemIds, invoiceId, claimId }) {
  try {
    let responses = yield all(
      invoiceVehicleItemIds.map((itemId) => call(powerGasClaimService.jobCostingRemoveVehicleItem, itemId, invoiceId))
    );
    if (isEmpty(responses)) {
      throw new Error("Error removing vehicle items");
    }
    let statusMessage = buildAlertMessage("Vehicle Items removed");

    const responseData = yield call(powerGasClaimService.getJobCostingInvoice, claimId);
    let { response } = responseData;
    yield put({
      type: powerGasClaimConstants.GET_JOB_COSTING_INVOICE_POWER_GAS_SUCCESS,
      payload: { claimId, data: { ...response } },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: {
        message: `${statusMessage}`,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimJobCostingRemoveContractorItemPowerGasSaga({ invoiceContractorItemIds, invoiceId, claimId }) {
  try {
    let responses = yield all(
      invoiceContractorItemIds.map((itemId) =>
        call(powerGasClaimService.jobCostingRemoveContractorItem, itemId, invoiceId)
      )
    );
    if (isEmpty(responses)) {
      throw new Error("Error removing contractor items");
    }
    let statusMessage = buildAlertMessage("Contractor Items removed");

    const responseData = yield call(powerGasClaimService.getJobCostingInvoice, claimId);
    let { response } = responseData;
    yield put({
      type: powerGasClaimConstants.GET_JOB_COSTING_INVOICE_POWER_GAS_SUCCESS,
      payload: { claimId, data: { ...response } },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: {
        message: `${statusMessage}`,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimJobCostingRemoveLossOfGasItemPowerGasSaga({ invoiceLossOfGasItemIds, invoiceId, claimId }) {
  try {
    let responses = yield all(
      invoiceLossOfGasItemIds.map((itemId) =>
        call(powerGasClaimService.jobCostingRemoveLossOfGasItem, itemId, invoiceId)
      )
    );
    if (isEmpty(responses)) {
      throw new Error("Error removing loss of gas items");
    }
    let statusMessage = buildAlertMessage("Loss of gas Items removed");

    const responseData = yield call(powerGasClaimService.getJobCostingInvoice, claimId);
    let { response } = responseData;
    yield put({
      type: powerGasClaimConstants.GET_JOB_COSTING_INVOICE_POWER_GAS_SUCCESS,
      payload: { claimId, data: { ...response } },
    });

    yield put({
      type: alertConstants.SUCCESS,
      payload: {
        message: `${statusMessage}`,
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimJobCostingSheetUploadPowerGasSaga({ file, name, claimId, userId, username }) {
  try {
    yield call(powerGasClaimService.uploadJobCostingFile, file, name, claimId, userId, username);
    let startOffset = encodeURI(`PowerGas/claims/${claimId}`);

    const responseFetch = yield call(powerGasClaimService.getFiles, startOffset);

    yield put({
      type: powerGasClaimConstants.GET_CLAIM_ATTACHMENTS_POWER_GAS_SUCCESS,
      payload: { files: [...responseFetch], claimId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimDocumentUploadPowerGasSaga({
  file,
  name,
  claimId,
  userId,
  username,
  parent,
  category,
  fileType,
}) {
  try {
    yield call(
      powerGasClaimService.claimUploadDocument,
      file,
      name,
      claimId,
      userId,
      username,
      parent,
      category,
      fileType
    );

    let startOffset = encodeURI(`PowerGas/claims/${claimId}`);

    const responseFetch = yield call(powerGasClaimService.getFiles, startOffset);

    yield put({
      type: powerGasClaimConstants.GET_CLAIM_ATTACHMENTS_POWER_GAS_SUCCESS,
      payload: { files: [...responseFetch], claimId },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimInvoiceDocPowerGasSaga({ claimId }) {
  try {
    const response = yield call(powerGasClaimService.getInvoiceDoc, claimId);

    let { payload } = response;
    yield put({
      type: powerGasClaimConstants.GET_CLAIM_INVOICE_DOCUMENT_POWER_GAS_SUCCESS,
      payload: { claimId, data: { ...payload } },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimDocPowerGasSaga({ claimId, letterType, contacts, reason }) {
  try {
    let encodeContacts = contacts ? `&contactsId=${contacts}` : "";
    let encodeReason = reason ? `&reason=${encodeURIComponent(reason)}` : "";
    const data = yield call(powerGasClaimService.getClaimDoc, claimId, letterType, encodeContacts, encodeReason);

    let response = _getProperty(data, ["response"]);
    yield put({
      type: powerGasClaimConstants.GET_CLAIM_DOCUMENT_POWER_GAS_SUCCESS,
      payload: { letterType, claimId, data: { ...response } },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimDocPowerGasSchemaReasonSaga() {
  try {
    const data = yield call(powerGasClaimService.getClaimDocSchemaReason);

    let response = _getProperty(data, ["response"]);
    yield put({
      type: powerGasClaimConstants.GET_CLAIM_DOCUMENT_SCHEMA_REASON_POWER_GAS_SUCCESS,
      payload: { data: response },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* addClaimDocPowerGasSchemaReasonSaga({ reason }) {
  try {
    yield call(powerGasClaimService.addClaimDocSchemaReason, reason);

    const data = yield call(powerGasClaimService.getClaimDocSchemaReason);

    let response = _getProperty(data, ["response"]);

    yield put({
      type: powerGasClaimConstants.GET_CLAIM_DOCUMENT_SCHEMA_REASON_POWER_GAS_SUCCESS,
      payload: { data: response },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* editClaimDocPowerGasSchemaReasonSaga({ LRID, LetterReason }) {
  try {
    yield call(powerGasClaimService.editClaimDocSchemaReason, LRID, LetterReason);

    yield put({
      type: powerGasClaimConstants.EDIT_CLAIM_DOCUMENT_SCHEMA_REASON_POWER_GAS_SUCCESS,
      payload: {},
    });

    const data = yield call(powerGasClaimService.getClaimDocSchemaReason);

    let response = _getProperty(data, ["response"]);

    yield put({
      type: powerGasClaimConstants.GET_CLAIM_DOCUMENT_SCHEMA_REASON_POWER_GAS_SUCCESS,
      payload: { data: response },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* removeClaimDocPowerGasSchemaReasonSaga({ LRID }) {
  try {
    yield call(powerGasClaimService.removeClaimDocSchemaReason, LRID);
    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Reason Deleted" },
    });

    const data = yield call(powerGasClaimService.getClaimDocSchemaReason);

    let response = _getProperty(data, ["response"]);

    yield put({
      type: powerGasClaimConstants.GET_CLAIM_DOCUMENT_SCHEMA_REASON_POWER_GAS_SUCCESS,
      payload: { data: response },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimDocPowerGasSchemaContactsSaga() {
  try {
    const data = yield call(powerGasClaimService.getClaimDocSchemaContacts);

    let response = _getProperty(data, ["response"]);
    yield put({
      type: powerGasClaimConstants.GET_CLAIM_DOCUMENT_SCHEMA_CONTACTS_POWER_GAS_SUCCESS,
      payload: { data: response },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimDocPowerGasSchemaLastSentenceSaga({ entityId }) {
  try {
    const data = yield call(powerGasClaimService.getClaimDocSchemaLastSentence, entityId);

    let response = _getProperty(data, ["response"]);

    yield put({
      type: powerGasClaimConstants.GET_CLAIM_DOCUMENT_SCHEMA_LAST_SENTENCE_POWER_GAS_SUCCESS,
      payload: { entityId, data: response },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimAttachmentSchemaImageCategoriesPowerGas() {
  try {
    const data = yield call(powerGasClaimService.getClaimAttachmentSchemaImageCategory);

    let response = _getProperty(data, ["response"]);
    yield put({
      type: powerGasClaimConstants.GET_CLAIM_ATTACHMENT_SCHEMA_IMAGE_CATEGORY_POWER_GAS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimAttachmentSchemaVideoCategoriesPowerGas() {
  try {
    const data = yield call(powerGasClaimService.getClaimAttachmentSchemaVideoCategory);

    let response = _getProperty(data, ["response"]);

    yield put({
      type: powerGasClaimConstants.GET_CLAIM_ATTACHMENT_SCHEMA_VIDEO_CATEGORY_POWER_GAS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* claimAttachmentSchemaDocumentCategoriesPowerGas({ parentType }) {
  try {
    const data = yield call(powerGasClaimService.getClaimAttachmentSchemaDocumentCategory, parentType);
    let response = _getProperty(data, ["response"]);
    yield put({
      type: powerGasClaimConstants.GET_CLAIM_ATTACHMENT_SCHEMA_DOCUMENT_CATEGORY_POWER_GAS_SUCCESS,
      payload: { data: response, parentType },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getClaimNarrativeCreatorDocPowerGasSaga({ claimId }) {
  try {
    const data = yield call(powerGasClaimService.getClaimNarrativeCreatorDoc, claimId);

    let response = _getProperty(data, ["response"]);
    yield put({
      type: powerGasClaimConstants.GET_CLAIM_NARRATIVE_CREATOR_DOCUMENT_POWER_GAS_SUCCESS,
      payload: { claimId, data: response },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getClaimIntentToBillDocPowerGasSaga({ claimId }) {
  try {
    const data = yield call(powerGasClaimService.getClaimIntentToBillDoc, claimId);

    let response = _getProperty(data, ["response"]);
    yield put({
      type: powerGasClaimConstants.GET_CLAIM_INTENT_TO_BILL_DOCUMENT_POWER_GAS_SUCCESS,
      payload: { claimId, data: response },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getClaimPromissoryNoteDocPowerGasSaga({ claimId }) {
  try {
    const data = yield call(powerGasClaimService.getClaimPromissoryNoteDoc, claimId);
    let response = _getProperty(data, ["response"]);

    yield put({
      type: powerGasClaimConstants.GET_CLAIM_PROMISSORY_DOCUMENT_POWER_GAS_SUCCESS,
      payload: { claimId, data: response },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getClaimLegalFinalNoticeDocPowerGasSaga({ claimId }) {
  try {
    const data = yield call(powerGasClaimService.getClaimLegalFinalNoticeDoc, claimId);
    let response = _getProperty(data, ["response"]);

    yield put({
      type: powerGasClaimConstants.GET_CLAIM_LEGAL_FINAL_NOTICE_DOCUMENT_POWER_GAS_SUCCESS,
      payload: { claimId, data: response },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getClaimFinalNoticeDocPowerGasSaga({ claimId }) {
  try {
    const data = yield call(powerGasClaimService.getClaimFinalNoticeDoc, claimId);
    let response = _getProperty(data, ["response"]);

    yield put({
      type: powerGasClaimConstants.GET_CLAIM_FINAL_NOTICE_DOCUMENT_POWER_GAS_SUCCESS,
      payload: { claimId, data: response },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getClaimReleaseLetterDocPowerGasSaga({ claimId }) {
  try {
    const data = yield call(powerGasClaimService.getClaimReleaseLetterDoc, claimId);
    let response = _getProperty(data, ["response"]);

    yield put({
      type: powerGasClaimConstants.GET_CLAIM_RELEASE_LETTER_DOCUMENT_POWER_GAS_SUCCESS,
      payload: { claimId, data: response },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getClaimSecondNoticeDocPowerGasSaga({ claimId }) {
  try {
    const data = yield call(powerGasClaimService.getClaimSecondNoticeDoc, claimId);

    let response = _getProperty(data, ["response"]);
    yield put({
      type: powerGasClaimConstants.GET_CLAIM_SECOND_NOTICE_DOCUMENT_POWER_GAS_SUCCESS,
      payload: { claimId, data: response },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getClaimPaymentReceiptPowerGasSaga({ claimId, ledgerId }) {
  try {
    const data = yield call(powerGasClaimService.getClaimPaymentReceiptDoc, claimId, ledgerId);

    let response = _getProperty(data, ["response"]);
    yield put({
      type: powerGasClaimConstants.GET_CLAIM_PAYMENT_RECEIPT_DOCUMENT_POWER_GAS_SUCCESS,
      payload: { claimId, ledgerId, data: response },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* getClaimBalanceOwedPowerGasSaga({ claimId }) {
  try {
    const data = yield call(powerGasClaimService.getClaimBalanceOwedDoc, claimId);

    let response = _getProperty(data, ["response"]);
    yield put({
      type: powerGasClaimConstants.GET_CLAIM_BALANCE_OWED_DOCUMENT_POWER_GAS_SUCCESS,
      payload: { claimId, data: response },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* deletePowerGasLedgerSaga({ ledgerId, claimId }) {
  try {
    const res = yield call(powerGasClaimService.deleteLedger, ledgerId, claimId);

    const response = yield call(jointLedgerService.getLedgers, "P&G", claimId);

    yield put({
      type: jointLedgerConstants.GET_LEDGERS_SUCCESS,
      payload: {
        lob: "powerGas",
        ledgers: { ...response },
      },
    });

    if (res["status"] !== 200) {
      throw new Error("Error Deleting Ledger");
    }

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Ledger Deleted" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* updatePowerGasLedgerSaga({ payload, watchId, cb }) {
  try {
    let transformData = Object.keys(payload).reduce((prev, id) => {
      let item = { ...payload[id], id };
      return [...prev, item];
    }, []);

    yield all(transformData.map((item) => call(powerGasClaimService.updateLedger, item, watchId, cb)));

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Ledger Updated" },
    });

    const response = yield call(powerGasClaimService.getClaimById, watchId, []);

    yield put({
      type: powerGasClaimConstants.GET_CLAIM_BY_ID_POWER_GAS_SUCCESS,
      payload: {
        watchId,
        claim: { ...response },
      },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* deletePowerGasStatusNoteSaga({ id, watchId }) {
  try {
    const res = yield call(powerGasClaimService.deleteStatusNote, id);

    const response = yield call(powerGasClaimService.getClaimById, watchId, []);

    yield put({
      type: powerGasClaimConstants.GET_CLAIM_BY_ID_POWER_GAS_SUCCESS,
      payload: {
        watchId,
        claim: { ...response },
      },
    });

    if (res["status"] !== 200) {
      throw new Error("Error Deleting Status Note");
    }

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Status Note Deleted" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* deletePowerGasActionNoteSaga({ actionId, claimId }) {
  try {
    const res = yield call(powerGasClaimService.deleteActionNote, actionId, claimId);

    // put: loading true
    yield put({
      type: powerGasClaimConstants.GET_CLAIM_BY_ID_POWER_GAS_LOADING_TRUE,
    });

    const response = yield call(powerGasClaimService.getClaimById, claimId, []);

    yield put({
      type: powerGasClaimConstants.GET_CLAIM_BY_ID_POWER_GAS_SUCCESS,
      payload: {
        claimId,
        claim: { ...response },
      },
    });

    if (res["status"] !== 200) {
      throw new Error("Error Deleting Action Note");
    }

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Action Note Deleted" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}

export function* savePowGasColumnFiltersSaga({ filters }) {
  try {
    const res = yield call(powerGasClaimService.saveColumnFilters, filters);

    if (res["status"] !== 200) {
      throw new Error("Error Saving Column Filters");
    }

    yield put({
      type: alertConstants.SUCCESS,
      payload: { message: "Column Filters Updated" },
    });
  } catch (error) {
    if (error.message === "Invalid Auth Token") {
      yield put({
        type: authConstants.LOGOUT,
      });
    }
    yield put({
      type: alertConstants.ERROR,
      payload: { message: error.message },
    });
  }
}
