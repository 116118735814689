import React from "react";
import { withRouter } from "react-router";
import { errorActions, userActions } from "_actions";
import _getProperty from "lodash/get";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    if (this.state.hasError) {
      const timestamp = new Date();
      const location = this?.props?.history?.location?.pathname;

      this.props.postError(error, errorInfo, location, timestamp);

      return;
    }
  }

  componentDidUpdate(previousProps, previousState) {
    if (previousProps.children !== this.props.children)
      this.setState({ hasError: false });
  }

  render() {
    const historyGoBack = () => {
      const pathName = this?.props?.history?.location?.pathname;

      if (pathName === "/dashboard") {
        this.setState({ hasError: false });
      } else {
        this.props.history.goBack();
      }
    };

    if (this.state.hasError) {
      return (
        <div
          style={{
            width: "100%",
            maxWidth: "600px",
            padding: "20px",
            margin: "auto",
            textAlign: "center",
            border: "solid thin var(--White)",
          }}
        >
          <h2 style={{ color: "var(--White)", textTransform: "uppercase" }}>
            An error has occured during the operation
          </h2>
          <h3>
            This error has been logged and an administrator will be notified
          </h3>
          <Button
            variant="contained"
            style={{ backgroundColor: "var(--DarkGray)" }}
            onClick={(e) => historyGoBack()}
          >
            Go Back
          </Button>
        </div>
      );
    }
    return this.props.children;
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

function mapDispatchToProps(dispatch) {
  const { postError } = errorActions;
  const { logout } = userActions;
  return {
    dispatch,
    ...bindActionCreators({ postError, logout }, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary)
);
