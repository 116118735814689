export const getApiBase = () => {
  if (process.env.REACT_APP_TEST_API_CALLS === "true") {
    return "http://localhost:4000/api"; // local host testing
  }
  if (process.env.REACT_APP_API_ENV) {
    return `https://${process.env.REACT_APP_API_ENV}.prgconsulting.net/api`;
  }

  return "https://dev.prgconsulting.net/api";
};

export const getWsBase = () => {
  if (process.env.REACT_APP_TEST_API_CALLS === "true") {
    return "ws://localhost:4000/"; // local host testing
  }
  if (process.env.REACT_APP_API_ENV) {
    return `wss://${process.env.REACT_APP_API_ENV}.prgconsulting.net/api/`;
  }

  return "wss://dev.prgconsulting.net/api/";
};
